import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './redux/reducers'
import Layout from './components/layout/Layout'
import './assets/boxicons-2.0.7/css/boxicons.min.css'
import 'react-phone-input-2/lib/bootstrap.css'

//import './assets/css/grid.css'
import './assets/css/theme.css'
import './assets/css/index.css'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const store = createStore(
  rootReducer
)

document.title = 'İnsanizi'

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Layout />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

