export const API_ENDPOINTS = {
  BASE_URL: "https://api.insanizi.org",
  TOKEN_URL: "/token",
  USER_URL: "/api/User/GetUserInfo",
  ADD_DONATION_TYPE: "/api/Donation/AddDonationType",
  ADD_CATEGORY: "/api/Category/AddDonationCategory",
  CATEGORY_LIST: "/api/Category/GetCategoryList",
  DONATIONS: "/api/Donation/GetDonationList",
  DONATION_TYPE: "/api/Donation/GetAllDonationTypeOrdered",
  DONATION_TYPE_FOR_LIST: "/api/Donation/GetDonationType",
  REMOVE_DONATION: "/api/Donation/RemoveDonationType",
  UPDATE_DONATION_TYPE: "/api/Donation/UpdateDonationType",
  UPDATE_DONATION_IMAGE: "/api/Donation/UpdateDonationFile",
  UPDATE_DONATION_PROJECT_IMAGE: "/api/Donation/UpdateProjectFile",
  DONATION_SAVE_IMAGE: "/api/Donation/SaveFile",
  DONATION_SAVE_PROJECT_IMAGE: "/api/Donation/SaveProjectFile",
  CATEGORY_SAVE_IMAGE: "/api/Category/SaveCategoryFile",
  REMOVE_CATEGORY: "/api/Category/RemoveCategory",
  UPDATE_CATEGORY: "/api/Category/UpdateCategory",
  CATEGORY_UPDATE_IMAGE: "/api/Category/UpdateCategoryFile",
  GET_DONATION_LIST: "/api/Donation/GetDonationList",
  SAVE_DONATION_VIDEO: "/api/Video/Upload",
  UPDATE_DONATION: "/api/Donation/UpdateDonation",
  SMS_VIDEO: "/api/SMS/SendSmsByGroupNo",
  SAVE_GROUP_NO_VIDEO: "/api/Video/UploadByGroupNo",
  GET_DASHBOARD: "/api/Report/DonationReport",
  DELETE_DONATION: "/api/Donation/DeleteDonation",
  ORDER_CATEGORY: "/api/Category/UpdateDonationSequence",
  ADD_DONATION: "/api/Donation/AddDonationByAdmin",
  ORDER_GROUP_NO: "/api/donation/SetOrderedGroupNo",
  GET_USERS: "/api/User/GetUserList",
  GET_PERMISSION: "/api/Permission/GetPermissionList",
  ADD_USERS: "/api/User/AddUser",
  DELETE_USER: "/api/User/DeleteUser",
  UPDATE_USER: "/api/User/UpdateUser",
  GET_TAB: "/api/Home/GetListTab",
  ORDER_TAB: "/api/Home/GetListTab",
  DELETE_TAB: "/api/Home/RemoveTab",
  UPDATE_SLIDER: "/api/Home/UpdateHomePageInfo",
  DELETE_DATA: "/api/Report/GetRemovedDonation",
  GET_SLIDER: "/api/Home/GetHomePageInfo",
  ADD_SLIDER: "/api/Home/AddHomePageInfo",
  DELETE_SLIDER: "/api/Home/RemoveHomePageInfo",
  ADD_SLIDER_PHOTO: "/api/Home/SaveHomePageFile",
  ADD_NEWS: "/api/News/AddNews",
  UPDATE_NEWS: "/api/News/UpdateNews",
  UPDATE_NEWS_SEQUENCE: "/api/News/UpdateNewsSequence",
  DELETE_NEWS: "/api/News/RemoveNews",
  ADD_NEWS_IMAGE: "/api/News/SaveNewsFile",
  UPDATE_NEWS_IMAGE: "/api/News/UpdateNewsFile",
  GET_NEWS: "/api/News/GetNews",
  ADD_DONATION_TAB_IMAGE: "/api/Donation/SaveDonationTabFile",
  UPDATE_DONATION_TAB_IMAGE: "/api/Donation/UpdateDonationTabFile",
  PAYMENT_CONTROL: "/api/Donation/PaymentControl",
  PAYMENT_ACTIVE: "/api/Donation/SetPayTrue",
  REMOVE_VIDEO: "/api/Video/RemoveVideo",
  CHANGE_CALL: "/api/Donation/SetDonationStatus",
  ORDER_DONATION_TYPES: "/api/Donation/UpdateDonationTypeSequence",
  ORDER_SLIDER: "/api/Home/UpdateSliderSequence",
  GET_DONATION_IMAGE: "/api/Donation/GetDonationImage?donationId=",
  GET_CATEGORY_IMAGE: "/api/Donation/GetCategoryImage?categoryId=",
  GET_PROJECT_IMAGE: "/api/Home/GetProjectImage?projectId=",
  GET_DONATION_TAB_IMAGE: "/api/Donation/GetDonationTabFile?id=",
  GET_SLIDER_IMAGE: "/api/Home/GetHomeImage?homeTabId=",
  GET_NEWS_IMAGE: "/api/News/GetNewsImage?newsId="
}
