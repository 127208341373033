import React from 'react'
import { Column } from 'primereact/column';
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import Remotes from '../Remotes';
import Table from '../components/table/Table';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import PhoneInput from 'react-phone-input-2';
import { ProgressBar } from 'primereact/progressbar';

import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';



const DeleteDonation = () => {

    let startDatetime = new Date();
    startDatetime.setMonth(startDatetime.getMonth() - 3)
    let endDateTime = new Date();
    const [dataDonations, setDataDonations] = useState([]);
    const[optionDonationType, setOptionDonationType] = React.useState([]);
    const[optionDonationTypes, setOptionDonationTypes] = React.useState([]);

    const[donationType, setDonationType] = React.useState(0);
    const[payStatus, setPayStatus] = React.useState(true);
    const[onlyBank, setOnlyBank] = React.useState(false);
    const[loadingFilter, setLoadingFilter] = React.useState(false);
    const[startDate, setStartDate] = React.useState(startDatetime);
    const[endDate, setEndDate] = React.useState(endDateTime);
    const dt = React.useRef(null);

    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedUpdateImage, setSelectedUpdateImage] = useState();
    const [selectedRow, setSelectedRow] = useState();


    const [permissonAdd, setPermissonAdd] = useState(false);
    const [permissonUpdate, setPermissonUpdate] = useState(false);
    const [permissonDelete, setPermissonDelete] = useState(false);


    const [optionsColumn, setOptionsColumn] = useState([]);
    const [selectColumn, setSelectColumn] = useState([]);
    const [selectColumn2, setSelectColumn2] = useState([]);
    const [linkType, setLinkType] = useState()
    const [link, setLink] = useState();

    const [globalSearch, setGlobalSearch] = useState();

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'DonorNameSurname': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonorPhoneNumber': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonorEmail': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonationType': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'Amount': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonationType': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'OtherDonationInfoVM.NameSurname': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'OtherDonationInfoVM.PhoneNumber': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'OtherDonationInfoVM.DonorEmail': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonotionQuantity': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'IsPay': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'RefCode': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'GroupNo': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'DonationCode': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'VideoLink': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'AddedUserNameSurname': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'CreatedDate': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]}
    });


    const toast = React.useRef(null);
    const isPhone = /^[0-9\b]+$/;
    const isMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const colsExport = [
        { field: 'DonorNameSurname', header: 'Ad Soyad' },
        { field: 'DonorPhoneNumber', header: 'Telefon No' },
        { field: 'DonorEmail', header: 'E-Posta' },
        { field: 'DonationType', header: 'Bağış Türü' },
        { field: 'Amount', header: 'Tutar' },
        { field: 'IsPay', header: 'Ödeme Durumu' },
        { field: 'RefCode', header: 'Referans Kodu' },
        { field: 'GroupNo', header: 'Grup No' },
        { field: 'DonationCode', header: 'Ödeme Kodu' },
        { field: 'CreatedDate', header: 'Kayıt Tarihi' },
    ]

    const booleanChecker = (rowData, item) => {
        if (typeof rowData[item.field] === "boolean") {
          return rowData[item.field] ? "Aktif" : "Pasif";
        } else {
          return rowData[item.field];
        }
      };

    // const statusChecker = (rowData, item) => {
    //     debugger
    //     if (rowData['CreatedDate'] === rowData['UpdatedDate']) {
    //       return "Silinmiş" 
    //     } else {
    //       return "Güncellenmiş"
    //     }
    //   };

    // const statusChecker = (rowData, item) => {
    // if (rowData[item.field]) {
    //     return <span className='product-badge'></span>;
    // } else {
    //     return <span className={`product-badge status-${(rowData.inventoryStatus ? rowData.inventoryStatus.toLowerCase() : '')}`}>{rowData.inventoryStatus}</span>;
    // }
    // };
    
    const dateTemplate =(rowData, column)=>{
        let date = new Date(rowData.CreatedDate)
        return date.toLocaleString();
   }


   const columnsData = [ 
        <Column key={18} field="Status" header="Bağış Durumu" sortable filter ></Column>,
        <Column key={2} field="DonorNameSurname" header="Ad Soyad" sortable filter  ></Column>, 
        <Column key={3} field="DonorPhoneNumber" header="Telefon No"  sortable filter ></Column>, 
        <Column key={4} field="DonorEmail" header="E-Posta" sortable filter   ></Column>, 
        <Column key={5} field="DonationType" header="Bağış Türü" sortable filter ></Column>, 
        <Column key={6} field="Amount" header="Tutar"  sortable filter ></Column>, 
        <Column key={7} field="OtherDonationInfoVM.NameSurname" header="Başkasının Adına Ad Soyad" sortable filter   ></Column>, 
        <Column key={8} field="OtherDonationInfoVM.PhoneNumber" header="Başkasının Adına Telefon" sortable filter  ></Column>, 
        <Column key={9} field="OtherDonationInfoVM.DonorEmail" header="Başkasının Adına E-Posta" sortable filter  ></Column>, 
        <Column key={10} field="DonotionQuantity" header="Paket Adeti" sortable filter  ></Column>, 
        <Column key={11} field="IsPay" header="Ödeme Durumu" body={booleanChecker} sortable filter ></Column>,
        //<Column key={18} field="VideoLink" header="Kanıt" body={statusChecker} sortable filter ></Column>,

        <Column key={12} field="RefCode" header="Referans Kodu"  sortable filter  ></Column>, 
        <Column key={13} field="GroupNo" header="Grup No" sortable filter ></Column>, 
        <Column key={14} field="DonationCode" header="Ödeme Kodu" sortable filter  ></Column>, 
        <Column key={15} field="VideoLink" header="Video Link" sortable filter  ></Column>, 
        <Column key={16} field="AddedUserNameSurname" header="Ekleyen Kullanıcı" sortable filter  ></Column>, 
        <Column key={17} field="CreatedDate" header="Kayıt Tarihi" body={dateTemplate} sortable filter ></Column>
    ];

    const columns2 = (col) => {
        
        let column = [<Column key={1} selectionMode="multiple" headerStyle={{ width: '1em' }}/>];
        setSelectColumn(col);
        col.map(item => {
            column.push(columnsData[item])
        });

        setSelectColumn2(column)

    }



    React.useEffect(() => {
        const columns = ['Bağış Durumu','Ad Soyad', 'Telefon No', 'E-Posta', 'Bağış Türü', 'Tutar', 'Başkasının Adına Ad Soyad', 'Başkasının Adına Telefon', 'Başkasının Adına E-Posta', 'Paket Adeti', 'Ödeme Durumu','Referans Kodu', 'Grup No', 'Ödeme Kodu', 'Video Link', 'Ekleyen Kullanıcı', 'Kayıt Tarihi' ];
        let col = []
        let select = []
        columns.map((item, ind) => {
            col.push({name: item, code: ind});
            select.push(ind);
        })
        setOptionsColumn(col);
        setSelectColumn(select);
        columns2(select);

        buttonFilter();
    }, [])


    const buttonFilter = () =>{
        setLoadingFilter(true);
        let response = {
            StartDate: new Date(startDate),
            EndDate: new Date(endDate),
        }
        Remotes.deleteData(response).then(r=>{
            if(r.status === 200) {
                setDataDonations(r.data);
            }
            setLoadingFilter(false);

        }).catch(()=> setLoadingFilter(false) )
    }
    const exportColumns = colsExport.map(col => ({ title: col.header, dataKey: col.field }));
    
    const exportPdf = () => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, dataDonations);
                doc.save('SilinenBagislar.pdf');
            })
        })
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            var records = dataDonations.map(item => {
                return {
                    AdSoyad: item.DonorNameSurname,
                    Telefon: item.DonorPhoneNumber,
                    EMail: item.DonorEmail,
                    BagisTipi: item.DonationType,
                    Adet: item.DonotionQuantity,
                    Tutar: item.Amount,
                    BaşkasınınAdınaAdSoyad: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.NameSurname : null,
                    BaşkasınınAdınaTelefon: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.PhoneNumber : null,
                    BaşkasınınAdınaEPosta: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.DonorEmail : null,
                    GrupNo: item.GroupNo,
                    OdemeDurumu: item.IsPay ? 'Aktif': 'Pasif',
                    RefCode: item.RefCode,
                    OdemeKodu: item.DonationCode,
                    EkleyenKullanici: item.AddedUserNameSurname,
                    KayitTarihi: item.CreatedDate,
                    GuncellemeTarihi: item.UpdatedDate
                }
            })
            const worksheet = xlsx.utils.json_to_sheet(records);
            
            const workbook = { Sheets: { 'bagislar': worksheet }, SheetNames: ['bagislar'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Silinenbagislar');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    const onGlobalFilterChange = (event, filtersKey) => {
        const value = event.target.value;
        let filters = { ...filtersMap[filtersKey].value };
        filters['global'].value = value;
        setGlobalSearch(filters['global'] ? filters['global'].value : '' )

        filtersMap[filtersKey].callback(filters);
    }

    const filtersMap = {
        'filters2': { value: filters2, callback: setFilters2 }
    };

    const header = (
        <div className="flex justify-content-between export-buttons">
            <div className='flex'>
                <span className="p-input-icon-left mr-5">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalSearch} onChange={(e) => onGlobalFilterChange(e, 'filters2')} placeholder="Arama..." />
                </span>
                <MultiSelect className='w-full' options={optionsColumn} value={selectColumn} onChange={r=> columns2(r.value)} optionLabel="name"  optionValue='code' maxSelectedLabels={3} placeholder="Seçiniz..."/>
            </div>
            <div>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger mr-2" data-pr-tooltip="PDF" />
            </div>
        </div>
    );


    return (
        <div>
             <Toast ref={toast} position="top-right" />
            <div className='flex justify-content-between' style={{height: 50}}>
                <h2 className="page-header mt-auto mb-auto">Silenen ve Güncellenen Bağışlar</h2>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className="fluid formgrid grid">
                               
                            <div className="field col-12 md:col-12 lg:col-4">
                                    <label className='mr-5 block'>Başlangıç Tarihi:</label>
                                    <Calendar className='w-full' showIcon   dateFormat='dd/mm/yy' value={startDate} onChange={e=>{setStartDate(e.value)}} ></Calendar>
                                </div>
                                <div className="field col-12 md:col-12 lg:col-4">
                                    <label className='mr-5 block'>Bitiş Tarihi:</label>
                                    <Calendar className='w-full' showIcon   dateFormat='dd/mm/yy' value={endDate} onChange={e=>{setEndDate(e.value)}} ></Calendar>
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-3">
                                    <Button className="p-button-info w-full" label="Filtre" loading={loadingFilter} onClick={buttonFilter} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table ref={dt} columns={selectColumn2}  header={header} value={dataDonations} 
                            filters={filters2} onFilter={(e) => setFilters2(e.filters)}  
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteDonation
