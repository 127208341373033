import React, { useEffect, useState, useRef } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import Remotes from '../Remotes';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { confirmPopup } from 'primereact/confirmpopup';
import { confirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from 'primereact/selectbutton';

import EmailEditor from 'react-email-editor';
import Table from '../components/table/Table';
import { Column } from 'primereact/column';
import { API_ENDPOINTS } from '../api';


const DonationTypes = () => {

    const [donationList, setDonationList] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedDonate, setSelectedDonate] = useState();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [smsDescription, setSmsDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [isShare, setIsShare] = useState(false);
    const [isShareMain, setIsShareMain] = useState(false);
    const [isHisse, setIsHisse] = useState(false);
    const [isVekalet, setIsVekalet] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [keyWords, setKeyWords] = useState();

    const [subGroup, setSubGroup] = useState(1);
    const [donationKind, setDonationKind] = useState(2);
    const [extraProperty, setExtraProperty] = useState(false);
    const [extraDescription, setExtraDescription] = useState(false);
    const [editor, setEditor] = useState();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [category, setCategory] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [selectedProjectImage, setSelectedProjectImage] = useState();
    const [selectedImageForMain, setSelectedImageForMain] = useState();
    const [updateLoading, setUpdateLoading] = useState(false);

    const subGroupOptions = [{ label: 'Vacip', value: 1 }, { label: 'Diğer', value: 2 }, { label: 'Su Kuyusu', value: 3 }, { label: 'Asya Su Kuyusu', value: 4 }];

    const [permissonUpdate, setPermissonUpdate] = useState(false);
    const [permissonDelete, setPermissonDelete] = useState(false);

    const [loading, setLoading] = useState(true)
    const emailEditorRef = useRef(null);
    const [view, setView] = useState('Tablo');

    const optionsView = ['Tablo', 'Liste'];

    const [properties, setProperties] = useState([
        {
            propertyName: "",
            propertyType: 0,
            selectOptions: ""
        }
    ]);

    const [generalDonationType, setGeneralDonationType] = useState([
        {
            buttonName: "",
            amount: 0
        }
    ]);

    const listDonationType = () => {
        Remotes.getDonationType().then(r => {
            if (r.status === 200) {
                setDonationList(r.data);
            }
        });
    }

    useEffect(() => {
        let getUserInfo = JSON.parse(localStorage.getItem('User'));

        if (getUserInfo) {
            getUserInfo.UserPermissionList.forEach((r) => {
                if (r === '/update-donation-type') {
                    setPermissonUpdate(true);
                } else if (r === '/delete-donation-type') {
                    setPermissonDelete(true);
                }
            })
        }
        Remotes.getCategory().then(r => {
            if (r.status === 200) {
                let options = [];
                r.data && r.data.map(t => {
                    options.push({ label: t.Name, value: t.ID })
                })
                setCategoryOptions(options);
            }
        });
        listDonationType();
    }, []);

    const donationClick = (donate) => {

        // const[donationKind, setDonationKind] = useState(2);
        if (permissonDelete || permissonUpdate) {
            setSelectedDonate(donate);
            setTitle(donate.DonationTitle);
            setDescription(donate.DonationDescription);
            setSmsDescription(donate.SmsDescription);
            setAmount(donate.Amount);
            setIsShare(donate.IsShare);
            setIsShareMain(donate.IsShareMain);
            setIsHisse(donate.IsHisse);
            setIsProject(donate.IsProject);
            setKeyWords(donate.KeyWords);
            setIsVekalet(donate.IsVekalet);
            if (donate.SourceCodeJSON) {
                setEditor(JSON.parse(donate.SourceCodeJSON));
                setExtraDescription(true);
            } else {
                setEditor(null);
                setExtraDescription(false);
            }
            setSubGroup(donate.Subgroup);
            setExtraProperty(donate.GetDonationTypePropertiesResponseVM.length > 0);
            setCategory(donate.CategoryID);
            setDonationKind(donate.DonationKindID);
            if (donate.GetDonationTypePropertiesResponseVM.length) {
                let propertiesList = [];
                donate.GetDonationTypePropertiesResponseVM.forEach(item => {
                    propertiesList.push({
                        propertyName: item.PropertyName,
                        propertyType: item.IsSelect ? 1 : 0,
                        selectOptions: item.ValueOfPropertiesList.toString()
                    })

                });
                setProperties(propertiesList);

            }

            if (donate.GenericDonationTypeViewModel.length) {
                let propertiesList = [];
                donate.GenericDonationTypeViewModel.forEach(item => {
                    propertiesList.push({
                        buttonName: item.ButtonName,
                        amount: item.Amount
                    });
                });
                setGeneralDonationType(propertiesList);
            }

            setDisplayDialog(true);
        }
    }

    const accept = () => {
        let response = {
            ID: selectedDonate.ID
        }
        Remotes.removeDonation(response).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',

                });
                setDisplayDialog(false);
                listDonationType();

            } else {
                confirmDialog({
                    message: 'Bir Hata Oluştu.',
                    header: 'Hata',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',

                });
            }

        })
    };

    const onHide = () => {
        setDisplayDialog(false);
    }

    const deleteDonation = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bağış Türünü Silmek İstediğinizden Emin Misiniz?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır'
        });
    };

    const updateDonation = () => {
        setUpdateLoading(true);
        let addDonationTypeProperties = [];
        properties && properties.map(item => {
            let obj = {
                PropertyName: item.propertyName,
                IsSelect: item.propertyType,
                Value: item.propertyType ? item.selectOptions.split(',') : null
            }
            addDonationTypeProperties.push(obj);
        });
        let genericDonationTypeRequest = [];
        generalDonationType && generalDonationType.map(item => {
            let obj = {
                ButtonName: item.buttonName,
                Amount: item.amount
            }
            genericDonationTypeRequest.push(obj);
        });
        let response = {
            DonationTypeID: selectedDonate.ID,
            UpdatedModel: {
                Title: title,
                Description: description,
                SMSDescription: smsDescription,
                Amount: amount,
                IsShare: isShare,
                IsShareMain: isShareMain,
                IsHisse: isHisse,
                IsVekalet: isVekalet,
                IsProject: isProject,
                KeyWords: keyWords,
                categoryID: category,
                SourceCode: null,
                SourceCodeJSON: null,
                SubGroup: isHisse ? subGroup : null,
                AddDonationTypePropertiesViewModel: extraProperty ? addDonationTypeProperties : [],
                DonationKindID: donationKind,
                GenericDonationTypeRequestViewModels: donationKind === 1 ? genericDonationTypeRequest : []
            }
        }
        const updateDonation = () => {
            Remotes.updateDonationType(response).then(r => {
                if (r.status === 200) {
                    if (selectedImage) {
                        var bodyFormData = new FormData();
                        bodyFormData.append('donationTypeId', selectedDonate.ID);
                        bodyFormData.append('image', selectedImage[0]);
                        Remotes.updateDonationImage(bodyFormData).then(t => {
                            if (t.status === 200) {
                                if (!selectedProjectImage && !selectedImageForMain) {
                                    confirmDialog({
                                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                        header: 'Başarılı',
                                        icon: 'pi pi-check-circle',
                                        acceptLabel: 'Tamam',
                                        rejectClassName: 'hidden',

                                    });
                                    setDisplayDialog(false);
                                    listDonationType();
                                    setUpdateLoading(false);
                                }

                            } else {
                                confirmDialog({
                                    message: 'Bir Hata Oluştu.',
                                    header: 'Hata',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    acceptLabel: 'Tamam',
                                    rejectClassName: 'hidden',

                                });
                                setUpdateLoading(false);

                            }
                        })
                    }
                    if (selectedProjectImage) {
                        var bodyFormData = new FormData();
                        bodyFormData.append('projectId', selectedDonate.ID);
                        bodyFormData.append('image', selectedProjectImage[0]);
                        Remotes.updateProjectImage(bodyFormData).then(t => {
                            if (t.status === 200) {
                                if (!selectedImageForMain) {
                                    confirmDialog({
                                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                        header: 'Başarılı',
                                        icon: 'pi pi-check-circle',
                                        acceptLabel: 'Tamam',
                                        rejectClassName: 'hidden',

                                    });
                                    setDisplayDialog(false);
                                    listDonationType();
                                    setUpdateLoading(false);

                                }

                            } else {
                                confirmDialog({
                                    message: 'Bir Hata Oluştu.',
                                    header: 'Hata',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    acceptLabel: 'Tamam',
                                    rejectClassName: 'hidden',

                                });
                                setUpdateLoading(false);

                            }
                        })
                    }
                    if (selectedImageForMain) {
                        var bodyFormData = new FormData();
                        bodyFormData.append('id', selectedDonate.ID);
                        bodyFormData.append('image', selectedImageForMain[0]);
                        Remotes.updateDonationTabImage(bodyFormData).then(t => {
                            if (t.status === 200) {
                                confirmDialog({
                                    message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                    header: 'Başarılı',
                                    icon: 'pi pi-check-circle',
                                    acceptLabel: 'Tamam',
                                    rejectClassName: 'hidden',

                                });
                                setDisplayDialog(false);
                                listDonationType();
                                setUpdateLoading(false);


                            } else {
                                confirmDialog({
                                    message: 'Bir Hata Oluştu.',
                                    header: 'Hata',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    acceptLabel: 'Tamam',
                                    rejectClassName: 'hidden',

                                });
                                setUpdateLoading(false);

                            }
                        })
                    }
                    else {
                        confirmDialog({
                            message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                            header: 'Başarılı',
                            icon: 'pi pi-check-circle',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',

                        });
                        setDisplayDialog(false);
                        listDonationType();
                        setUpdateLoading(false);

                    }
                } else {
                    confirmDialog({
                        message: 'Bir Hata Oluştu.',
                        header: 'Hata',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',

                    });
                    setUpdateLoading(false);

                }
            })
        }
        if (emailEditorRef?.current) {
            emailEditorRef.current.editor.exportHtml((data) => {
                const { design, html } = data;

                response.UpdatedModel.SourceCode = html;
                response.UpdatedModel.SourceCodeJSON = JSON.stringify(design);
                updateDonation();
            });
        }
        else {
            updateDonation();
        }

    }

    const removeGeneralDonationType = (index) => {
        if (generalDonationType.length > 1) {
            let allGeneralDonationType = generalDonationType.filter((item, i) => i !== index);
            setGeneralDonationType(allGeneralDonationType);
        }
    }

    const addButton = () => {
        setGeneralDonationType([...generalDonationType, {
            buttonName: "",
            amount: 0
        }])
    }

    const removeProperty = (index) => {
        if (properties.length > 1) {
            let allProperties = properties.filter((item, i) => i !== index);
            setProperties(allProperties);
        }
    }

    const addProperty = () => {
        setProperties([...properties, {
            propertyName: "",
            propertyType: 0,
            selectOptions: ""
        }])

    }

    const cardFooter = (
        <div>
            {
                permissonUpdate ?
                    <Button label="Güncelle" icon="pi pi-check" loading={updateLoading} onClick={updateDonation} />
                    : null
            }
            {
                permissonDelete ?
                    <Button label="Sil" icon="pi pi-times" className="p-button-danger" onClick={deleteDonation} />
                    : null

            }

        </div>
    );

    const setLoadEditor = () => {
        setTimeout(() => {
            if (emailEditorRef.current) {
                emailEditorRef?.current?.editor?.loadDesign(editor)
            }
            else {
                setLoadEditor();
            }
        }, 5000)
    }

    const onLoad = () => {
        if (editor && emailEditorRef?.current) {
            emailEditorRef?.current?.editor?.loadDesign(editor)
        }
        else {
            setLoadEditor();
        }
    }

    const onRowReorder = (e) => {
        let copyData = [...e.value];
        let orderArray = [];
        copyData.forEach(element => {
            orderArray.push(element.ID)
        })
        let response = {
            CategoryID: e.value[0].CategoryID,
            DonationTypeID: orderArray

        }
        console.log(response)
        Remotes.orderDonationTypes(response).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Sıralama başarılı bir şekilde kaydedildi!',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',

                });
                listDonationType();
            }
        })

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {
                    permissonUpdate ?
                        <Button icon="pi pi-pencil" className="p-button p-button-info mr-2" onClick={() => donationClick(rowData)} />
                        : null
                }
                {
                    permissonDelete ?
                        <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={(event) => {
                            setSelectedDonate(rowData);
                            deleteDonation(event);
                        }} />
                        : null
                }
            </React.Fragment>
        );
    }
    const columns = [
        <Column rowReorder style={{ width: '3em' }} />,
        <Column field="DonationTitle" header="Bağış Türü Adı" sortable filter />,
        <Column field="DonationDescription" header="Açıklama" style={{ maxWidth: '8rem' }} sortable filter />,

        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
    ]

    return (
        <div>
            <h2 className="page-header">Bağış Türleri</h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className='flex justify-content-end w-full my-2'>
                                <SelectButton value={view} options={optionsView} onChange={(e) => setView(e.value)} />
                            </div>
                            <TabView>
                                {
                                    donationList && donationList.map((d, i) => {
                                        return <TabPanel key={"ky" + i} header={d.CategoryTitle} className="flex flex-wrap">

                                            <div className="fluid formgrid grid" style={{ width: "100%" }}>
                                                {
                                                    view === 'Tablo' ?
                                                        d.List.map((val, ind) => {
                                                            if (donationList.length === (i + 1) && d.List.length === (ind + 1) && loading) {
                                                                setLoading(false)
                                                            }
                                                            return <div onClick={() => donationClick(val)} className='col_card field col-12 md:col-4' key={'donation_' + ind} ><Card className='donationTypeCard' title={val.DonationTitle} subTitle={val.DonationDescription} header={<img alt="Card" className={val.IsShare ? 'donation_card_img' : 'donation_card_img cardFilter'} src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_DONATION_IMAGE + val.ID} />} ></Card></div>
                                                        })
                                                        : <Table className="w-full" columns={columns} reorderableColumns onRowReorder={onRowReorder} value={d.List} />
                                                }
                                            </div>
                                        </TabPanel>
                                    })
                                }
                            </TabView>
                            {
                                loading ?
                                    <div style={{ margin: "auto", width: 100, height: 100 }} className="flex flex-column align-items-center">
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                        <p className="text-center" style={{ width: 150 }}>Bağış Türleri Yükleniyor</p>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Bağış Düzenle" visible={displayDialog} onHide={onHide} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} maximizable footer={cardFooter} >
                <div className="row">
                    <div className="col-12">
                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Bağış Başlığı:</label>
                                <InputText id="in" className='w-full' value={title} onChange={r => {
                                    setTitle(r.target.value);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Kategori</label>
                                <Dropdown className='w-full' options={categoryOptions}
                                    placeholder="Seçiniz..." value={category} onChange={e => {
                                        setCategory(e.value);
                                    }} />
                            </div>
                        </div>

                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Bağış Açıklaması:</label>
                                <InputTextarea id="in" className='w-full' rows={5} autoResize value={description} onChange={r => {
                                    setDescription(r.target.value);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>SMS Açıklaması:</label>
                                <InputTextarea id="in" className='w-full' rows={5} tooltip={["Sayın UMUT SARIKAYA İz Bıraktınız!\n" + smsDescription + "\nİletişim: 0850 532 25 40\nİnsan İzi Derneği"]} tooltipOptions={{ position: 'top' }} autoResize value={smsDescription} onChange={r => {
                                    setSmsDescription(r.target.value);
                                }} />
                            </div>
                        </div>
                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Bağış Tutarı:</label>
                                <InputNumber id="in" disabled={donationKind === 1} className='w-full' mode="currency" currency="TRY" locale="tr-TR" value={amount} onChange={r => {
                                    const re = /^[0-9\b]+$/;
                                    if (re.test(r.value)) {
                                        setAmount(r.value);
                                    }
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Yayınlansın Mı? :</label>
                                <InputSwitch id="in" className='d-block' checked={isShare} onChange={r => {
                                    setIsShare(r.value);
                                }} />
                            </div>
                        </div>
                        <div className='fluid formgrid grid' >
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Hisseli Mi? :</label>
                                <InputSwitch id="in" className='d-block' checked={isHisse} onChange={r => {
                                    setIsHisse(r.value);
                                }} />
                            </div>
                            {
                                isHisse ?
                                    <div className="field col-12 md:col-12 lg:col-6">
                                        <label className='mr-5 block'>Gruplama</label>
                                        <Dropdown className='w-full' options={subGroupOptions} disabled={!isHisse}
                                            placeholder="Seçiniz..." value={subGroup} onChange={e => {
                                                setSubGroup(e.value);
                                            }} />
                                    </div>
                                    : null
                            }

                        </div>
                        <div className='fluid formgrid grid'>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Vekalet İstensin Mi? :</label>
                                <InputSwitch id="in" className='d-block' checked={isVekalet} onChange={r => {
                                    setIsVekalet(r.value);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Ana Siteye Eklensin Mi? :</label>
                                <InputSwitch id="in" className='d-block' checked={isShareMain} onChange={r => {
                                    setIsShareMain(r.value);
                                }} />
                            </div>
                        </div>
                        <div className='fluid formgrid grid'>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Projelerde Gözüksün Mü? :</label>
                                <InputSwitch id="in" className='d-block' checked={isProject} onChange={r => {
                                    setIsProject(r.value);
                                }} />
                            </div>

                        </div>
                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Fotoğraf:</label>
                                <FileUpload accept="image/*" customUpload chooseLabel={selectedImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                    setSelectedImage(null);
                                }} uploadHandler={e => {
                                    setSelectedImage(e.files);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Fotoğraf Önizleme:</label>
                                <img height={200} src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_DONATION_IMAGE + (selectedDonate && selectedDonate.ID)} />
                            </div>
                        </div>
                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Projeler için Tasarım:</label>
                                <FileUpload accept="image/*" customUpload chooseLabel={selectedProjectImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                    setSelectedProjectImage(null);
                                }} uploadHandler={e => {
                                    setSelectedProjectImage(e.files);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Fotoğraf Önizleme:</label>
                                <img height={200} src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_PROJECT_IMAGE + (selectedDonate && selectedDonate.ID)} />
                            </div>
                        </div>
                        <div className="fluid formgrid grid">
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Anasite için Tasarım:</label>
                                <FileUpload accept="image/*" customUpload chooseLabel={selectedImageForMain ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                    setSelectedImageForMain(null);
                                }} uploadHandler={e => {
                                    setSelectedImageForMain(e.files);
                                }} />
                            </div>
                            <div className="field col-12 md:col-12 lg:col-6">
                                <label className='mr-5 block'>Fotoğraf Önizleme:</label>
                                <img height={200} src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_DONATION_TAB_IMAGE + (selectedDonate && selectedDonate.ID)} />
                            </div>
                        </div>
                        <div className="fluid my-5">
                            <h3>Bağış Tipi</h3>
                        </div>
                        <div className="field-radiobutton" >
                            <RadioButton inputId="normal" name="normal" value="Normal Bağış"
                                checked={donationKind === 2} onChange={e => {
                                    setDonationKind(2);
                                }} />
                            <label htmlFor="normal">Normal Bağış</label>
                        </div>
                        <div className="field-radiobutton" >
                            <RadioButton inputId="genel" name="genel" value="Genel Bağış Tipi"
                                checked={donationKind === 1} onChange={e => {
                                    setDonationKind(1);
                                }} />
                            <label htmlFor="genel">Genel Bağış Tipi</label>
                        </div>
                        {
                            donationKind === 1 ?
                                <>
                                    <div className="fluid my-5">
                                        <h3>Genel Bağış Özelleştir</h3>
                                    </div>
                                    {generalDonationType && generalDonationType.map((item, index) => {
                                        return (
                                            <div className="fluid formgrid grid" key={index}>
                                                <div className="field col-12 md:col-12 lg:col-4">
                                                    <label className='mr-5 block'>Buton Adı</label>
                                                    <InputText id="in" className='w-full' value={item.buttonName} onChange={e => {
                                                        let newGenenal = [...generalDonationType];
                                                        newGenenal[index].buttonName = e.target.value;
                                                        setGeneralDonationType(newGenenal);
                                                    }} />
                                                </div>
                                                <div className="field col-12 md:col-12 lg:col-4">
                                                    <label className='mr-5 block'>Tutar</label>
                                                    <InputNumber id="in" className='w-full' mode="currency" currency="TRY" locale="tr-TR" value={item.amount} onChange={e => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (re.test(e.value)) {
                                                            let newGenenal = [...generalDonationType];
                                                            newGenenal[index].amount = e.value;
                                                            setGeneralDonationType(newGenenal);
                                                        }

                                                    }} />
                                                </div>
                                                <div className="field  ml-3 flex align-items-end justify-content-center">
                                                    <Button className="p-button-danger" label="Sil" onClick={() => removeGeneralDonationType(index)} />
                                                </div>
                                            </div>
                                        )

                                    })}
                                    <div className='fluid formgrid grid'>
                                        <div className="field col">
                                            <Button className="p-button-success" label="Buton Ekle" onClick={addButton} />
                                        </div>
                                    </div>

                                </> : null
                        }
                        <div className="fluid my-5">
                            <h3>Anahtar Kelimeler</h3>
                        </div>
                        <div>
                            <InputTextarea className='w-full' value={keyWords} onChange={(e)=> setKeyWords(e.target.value)} />
                        </div>
                        <div className="fluid my-5">
                            <h3>Extra Açıklama</h3>
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="extraDesc" checked={extraDescription} onChange={e => setExtraDescription(e.checked)} />
                            <label htmlFor="extraDesc">Extra açıklama eklemek istiyorum.</label>
                        </div>
                        <div className={!extraDescription ? 'hidden' : null}>
                            <EmailEditor key={title} ref={emailEditorRef} onLoad={onLoad} minHeight="700px" />
                        </div>
                        <div className="fluid my-5">
                            <h3>Extra Özellikler</h3>
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={extraProperty} onChange={e => setExtraProperty(e.checked)} />
                            <label htmlFor="binary">Extra özellik eklemek istiyorum.</label>
                        </div>

                        {extraProperty ?
                            properties.map((pro, index) => {
                                return (<>
                                    <div className="fluid formgrid grid" key={index}>
                                        <div className="field col-12 md:col-12 lg:col-3">
                                            <label className='mr-5 block'>Özellik Adı</label>
                                            <InputText id="in" className='w-full' value={pro.propertyName} onChange={e => {
                                                let newPro = [...properties];
                                                newPro[index].propertyName = e.target.value;
                                                setProperties(newPro);
                                            }} />
                                        </div>
                                        <div className="field col-12 md:col-12 lg:col-4">
                                            <label className='mr-5 block'>Özellik Türü</label>
                                            <Dropdown className='w-full' options={[{ label: 'Paragraf', value: 0 }, { label: 'Seçmeli', value: 1 }]}
                                                placeholder="Seçiniz..." value={pro.propertyType} onChange={e => {
                                                    let newPro = [...properties];
                                                    newPro[index].propertyType = e.value;
                                                    setProperties(newPro);
                                                }} />
                                        </div>
                                        {
                                            pro.propertyType === 1 ?
                                                <div className="field col-12 md:col-12 lg:col-3">
                                                    <label className='mr-5 block'>Seçenekler</label>
                                                    <InputText id="in" className='w-full' value={pro.selectOptions} onChange={e => {
                                                        let newPro = [...properties];
                                                        newPro[index].selectOptions = e.target.value;
                                                        setProperties(newPro);

                                                    }} />
                                                </div> : null
                                        }

                                        <div className="field col-12 md:col-12 lg:col-2 flex align-items-end justify-content-center">
                                            <Button className="p-button-danger w-full" label="Sil" onClick={() => removeProperty(index)} />
                                        </div>
                                    </div>
                                    {
                                        index === properties.length - 1 ?
                                            <div className='fluid formgrid grid'>
                                                <div className="field col">
                                                    <Button className="p-button-success" label="Özellik Ekle" onClick={addProperty} />
                                                </div>
                                            </div>
                                            : null
                                    }
                                </>)
                            })
                            : null}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DonationTypes
