const setMode = mode => {
    return {
        type: 'SET_MODE',
        payload: mode
    }
}

const setColor = color => {
    return {
        type: 'SET_COLOR',
        payload: color
    }
}

const setToken = token => {
    return {
        type: 'SET_TOKEN',
        payload: token
    }
}

const setUser = user => {
    return {
        type: 'SET_USER',
        payload: user
    }
}

const getTheme = () => {
    return {
        type: 'GET_THEME'
    }
}
const getToken = () => {
    return {
        type: 'GET_TOKEN'
    }
}

const selectedDonation = (donate) => {
    return {
        type: 'SELECTED_DONATION',
        payload: donate
    }
}

const openSideBar = (isOpen) => {
    return {
        type: 'OPEN_SIDEBAR',
        payload: isOpen
    }
}

const exportDefault = {
    setColor,
    setMode,
    getTheme,
    setToken,
    getToken,
    setUser,
    selectedDonation,
    openSideBar
}

export default exportDefault