import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import Remotes from '../Remotes';
import Table from '../components/table/Table';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { useEffect } from 'react';

import { API_ENDPOINTS } from "../api";


const Donations = () => {

    const startDatetime = new Date();
    startDatetime.setMonth(startDatetime.getMonth() - 1);
    const endDateTime = new Date();
    const [dataDonations, setDataDonations] = useState([]);
    const [optionDonationType, setOptionDonationType] = React.useState([]);
    const [optionDonationTypes, setOptionDonationTypes] = React.useState([]);

    const [donationType, setDonationType] = React.useState(0);
    const [payStatus, setPayStatus] = React.useState(true);
    const [onlyBank, setOnlyBank] = React.useState(false);
    const [startDate, setStartDate] = React.useState(startDatetime);
    const [endDate, setEndDate] = React.useState(endDateTime);
    const dt = React.useRef(null);

    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedUpdateImage, setSelectedUpdateImage] = useState();
    const [selectedRow, setSelectedRow] = useState();

    const [updateName, setUpdateName] = useState(null);
    const [updatePhone, setUpdatePhone] = useState(null);
    const [updateMail, setUpdateMail] = useState(null);
    const [updateAmount, setUpdateAmount] = useState(null);
    const [updateGroupNo, setUpdateGroupNo] = useState(null);
    const [updateDonationType, setUpdateDonationType] = useState(null);

    const [updateOtherDonation, setUpdateOtherDonation] = useState(false);
    const [updateOtherName, setUpdateOtherName] = useState(null);
    const [updateOtherPhone, setUpdateOtherPhone] = useState(null);
    const [updateOtherEPosta, setUpdateOtherEPosta] = useState(null);


    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [displayDialogVideo, setDisplayDialogVideo] = useState(false);
    const [displayDialogDonation, setDisplayDialogDonation] = useState(false);
    const [displayDialogGrupNo, setDisplayDialogGrupNo] = useState(false);
    const [groupYear, setGroupYear] = useState('2023');
    const [groupType, setGroupType] = useState();


    const [loadVideo, setLoadVideo] = useState(0);

    const [newName, setNewName] = useState(null);
    const [newPhone, setNewPhone] = useState(null);
    const [newEPosta, setNewEPosta] = useState(null);
    const [newAmount, setNewAmount] = useState(null);
    const [newDonationType, setNewDonationType] = useState(0);
    const [newQuatity, setNewQuatity] = useState(1);
    const [newOtherDonation, setNewOtherDonation] = useState(false);
    const [newOtherName, setNewOtherName] = useState(null);
    const [newOtherPhone, setNewOtherPhone] = useState(null);
    const [newOtherEPosta, setNewOtherEPosta] = useState(null);

    const [loadingFilter, setLoadingFilter] = useState(false);

    const [permissonAdd, setPermissonAdd] = useState(false);
    const [permissonGroupNo, setPermissonGroupNo] = useState(false);
    const [permissonUpdate, setPermissonUpdate] = useState(false);
    const [permissonDelete, setPermissonDelete] = useState(false);

    const [loadingAddDonation, setLoadingAddDonation] = useState(false);

    const [optionsColumn, setOptionsColumn] = useState([]);
    const [selectColumn, setSelectColumn] = useState();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [linkType, setLinkType] = useState()
    const [link, setLink] = useState();

    const [userInfo, setUserInfo] = useState();

    const [globalSearch, setGlobalSearch] = useState();

    const subGroupOptions = [{ label: 'Vacip', value: 1 }, { label: 'Diğer', value: 2 }, { label: 'Su Kuyusu', value: 3 }, { label: 'Asya Su Kuyusu', value: 4 }];

    const [filters2, setFilters2] = useState(
        {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'DonorNameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonorPhoneNumber': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonorEmail': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonationType': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'Amount': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonationType': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'OtherDonationInfoVM.NameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'OtherDonationInfoVM.PhoneNumber': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'OtherDonationInfoVM.DonorEmail': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonotionQuantity': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'IsPay': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'RefCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'GroupNo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'DonationCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'VideoLink': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'AddedUserNameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'CreatedDate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
    }
    );

    // {
    //     // 'global': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }, { value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     'DonorNameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonorPhoneNumber': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonorEmail': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonationType': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'Amount': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonationType': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'OtherDonationInfoVM.NameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'OtherDonationInfoVM.PhoneNumber': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'OtherDonationInfoVM.DonorEmail': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonotionQuantity': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'IsPay': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'RefCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'GroupNo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'DonationCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'VideoLink': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'AddedUserNameSurname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //     'CreatedDate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
    // }

    const globalFilterObj = (value) => {
        return {
            'global': { value, matchMode: FilterMatchMode.CONTAINS },
            'DonorNameSurname': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonorPhoneNumber': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonorEmail': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonationType': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'Amount': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonationType': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'OtherDonationInfoVM.NameSurname': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'OtherDonationInfoVM.PhoneNumber': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'OtherDonationInfoVM.DonorEmail': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonotionQuantity': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'IsPay': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'RefCode': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'GroupNo': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'DonationCode': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'VideoLink': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'AddedUserNameSurname': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] },
            'CreatedDate': { operator: FilterOperator.OR, constraints: [{ value: value.toLocaleLowerCase('TR'), matchMode: FilterMatchMode.CONTAINS }, { value: value.toLocaleUpperCase('TR'), matchMode: FilterMatchMode.CONTAINS }] }
        }

    }

    

    const toast = React.useRef(null);
    const isPhone = /^[0-9\b]+$/;
    const isMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const booleanChecker = (rowData, item) => {
        if (typeof rowData[item.field] === "boolean") {
            return rowData[item.field] ? "Aktif" : "Pasif";
        } else {
            return rowData[item.field];
        }
    };

    const dateTemplate = (rowData, column) => {
        let date = new Date(rowData.CreatedDate)
        return date.toLocaleString();
    }

    const statusTemplate = (rowData) => {
        return rowData.DonationStatus === 1 ? '❤' : rowData.DonationStatus === 2 ? '💛' : rowData.DonationStatus === 3 ? '💚' : null;
    }

    const columnsData = [
        {
            header: "Durum",
            field: "Durum",
            body: statusTemplate
        },
        {
            header: "Ad Soyad",
            field: "DonorNameSurname"
        },
        {
            header: "Telefon No",
            field: "DonorPhoneNumber"
        },
        {
            header: "E-Posta",
            field: "DonorEmail"
        },
        {
            header: "Bağış Türü",
            field: "DonationType"
        },
        {
            header: "Tutar",
            field: "Amount"
        },
        {
            header: "Başkasının Adına Ad Soyad",
            field: "OtherDonationInfoVM.NameSurname"
        },
        {
            header: "Başkasının Adına Telefon",
            field: "OtherDonationInfoVM.PhoneNumber"
        },
        {
            header: "Başkasının Adına E-Posta",
            field: "OtherDonationInfoVM.DonorEmail"
        },
        {
            header: "Paket Adeti",
            field: "DonotionQuantity"
        },
        {
            header: "Ödeme Durumu",
            field: "IsPay",
            body: booleanChecker
        },
        {
            header: "Referans Kodu",
            field: "RefCode"
        },
        {
            header: "Grup No",
            field: "GroupNo"
        },
        {
            header: "Ödeme Kodu",
            field: "DonationCode"
        },
        {
            header: "Video Link",
            field: "VideoLink"
        },
        {
            header: "Ekleyen Kullanıcı",
            field: "AddedUserNameSurname"
        },
        {
            header: "Kayıt Tarihi",
            field: "CreatedDate",
            body: dateTemplate
        },
    ]

    const colsExport = [
        { field: 'DonorNameSurname', header: 'Ad Soyad' },
        { field: 'DonorPhoneNumber', header: 'Telefon No' },
        { field: 'DonorEmail', header: 'E-Posta' },
        { field: 'DonationType', header: 'Bağış Türü' },
        { field: 'Amount', header: 'Tutar' },
        { field: 'IsPay', header: 'Ödeme Durumu' },
        { field: 'RefCode', header: 'Referans Kodu' },
        { field: 'GroupNo', header: 'Grup No' },
        { field: 'DonationCode', header: 'Ödeme Kodu' },
        { field: 'CreatedDate', header: 'Kayıt Tarihi' },
    ]

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {
                    permissonUpdate ?
                        <Button icon="pi pi-pencil" className="p-button p-button-info mr-2" onClick={() => viewCategory(rowData)} />
                        : null
                }
                {
                    permissonDelete ?
                        <Button icon="pi pi-trash" className="p-button p-button-danger mr-2" onClick={() => deleteCategory(rowData)} />
                        : null
                }

                <Button icon="pi pi-caret-right" className="p-button mr-2 " onClick={() => videoWatch(rowData)} />
            </React.Fragment>
        );
    }

    const donationUpdate = () => {
        setLoadingUpdate(true);
        //TODO: otherDonation kontrolünü yap
        if (selectedRow.DonorNameSurname !== updateName || selectedRow.DonorPhoneNumber !== updatePhone ||
            selectedRow.DonorEmail !== updateMail || selectedRow.GroupNo !== updateGroupNo || selectedRow.Amount !== updateAmount ||
            !!selectedRow.OtherDonationInfoVM !== updateOtherDonation || selectedRow.OtherDonationInfoVM?.NameSurname != updateOtherName || selectedRow.OtherDonationInfoVM?.PhoneNumber != updateOtherPhone ||
            selectedRow.OtherDonationInfoVM?.DonorEmail != updateOtherEPosta || selectedRow.DonationTypeInfo?.ID != updateDonationType.ID) {
            let response = {
                DonationID: selectedRow.ID,
                NameSurname: updateName,
                PhoneNumber: updatePhone,
                Email: updateMail,
                GroupNo: updateGroupNo,
                Amount: updateAmount,
                DonationTypeID: updateDonationType.ID,
                IsOtherDonation: updateOtherDonation,
                OtherNameSurname: updateOtherDonation ? updateOtherName : null,
                OtherNumberPhone: updateOtherDonation ? updateOtherPhone : null,
                OtherMail: updateOtherDonation ? updateOtherEPosta : null
            };
            Remotes.updateDonation(response).then(r => {
                if (r.status === 200 && !selectedUpdateImage) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                    buttonFilter();
                    setDisplayDialog(false);
                }
                setLoadingUpdate(false);
            }).catch(() => setLoadingUpdate(false))
        }
        else if (!selectedUpdateImage) {
            confirmDialog({
                message: 'Değişiklik yapılmamıştır!',
                header: 'Uyarı',
                icon: 'pi pi-warnings-circle',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',
            });
            setLoadingUpdate(false);
        }
        else {
            var bodyFormData = new FormData();
            bodyFormData.append('id', selectedRow.ID);
            bodyFormData.append('video', selectedUpdateImage[0]);
            const onLoad = event => {
                let prog = Math.round((100 * event.loaded) / event.total);
                setLoadVideo(prog)
            }
            Remotes.saveDonationVideo(bodyFormData, onLoad).then(r => {
                if (r.status === 200) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                    buttonFilter();
                    setSelectedUpdateImage(null);
                    setDisplayDialog(false);
                    setLoadVideo(0);
                }
                setLoadingUpdate(false);
            }).catch(() => setLoadingUpdate(false))
        }
    }

    const onHide = () => {
        setDisplayDialog(false);
    }

    const onHideVideo = () => {
        setDisplayDialogVideo(false);
    }

    const onHideDonation = () => {
        setDisplayDialogDonation(false);
    }

    const onHideGrupNo = () => {
        setDisplayDialogGrupNo(false);
    }

    const viewCategory = (selected) => {
        debugger
        setSelectedRow(selected);
        setUpdateName(selected.DonorNameSurname);
        setUpdateMail(selected.DonorEmail);
        // let match = selectedRow?.GroupNo?.toString().match(/[A-Z]/g).join('');
        setUpdateGroupNo(selected.GroupNo);
        setUpdateDonationType({ID: selected.DonationTypeInfo.ID, DonationKindID: selected.DonationTypeInfo.DonationKindID, Amount: selected.DonationTypeInfo.Amount} )
        setUpdatePhone(selected.DonorPhoneNumber);
        setUpdateAmount(selected.Amount);
        setUpdateOtherDonation(!!selected.OtherDonationInfoVM);
        setUpdateOtherName(selected.OtherDonationInfoVM && selected.OtherDonationInfoVM.NameSurname ? selected.OtherDonationInfoVM.NameSurname : null);
        setUpdateOtherPhone(selected.OtherDonationInfoVM && selected.OtherDonationInfoVM.PhoneNumber ? selected.OtherDonationInfoVM.PhoneNumber : null);
        setUpdateOtherEPosta(selected.OtherDonationInfoVM && selected.OtherDonationInfoVM.DonorEmail ? selected.OtherDonationInfoVM.DonorEmail : null);

        setDisplayDialog(true);
    }

    const removeDonation = (selected) => {
        let response = {
            DonationID: selected.ID
        };
        Remotes.deleteDonation(response).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Silme İşlemi Başarılı Bir Şekilde Yapıldı.',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                buttonFilter();
            }
        })
    }

    const deleteCategory = (selected) => {
        setSelectedRow(selected);
        confirmDialog({
            message: selected.DonorNameSurname + " ait bağış silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => removeDonation(selected)

        });

        setDisplayDialog(false);


    }

    const videoWatch = (selected) => {
        setSelectedRow(selected);
        setLinkType(null);
        if (selected.VideoLink) {
            let split = selected.VideoLink.split('#');
            setLinkType(split[0])
            setLink(split[1])
        }
        setDisplayDialogVideo(true);

    }

    useEffect(() => {
        let getUserInfo = JSON.parse(localStorage.getItem('User'));
        const getFilterStatus = JSON.parse(localStorage.getItem("filterStatus"));
        const currentDate = new Date().getDate();
        if(getFilterStatus && currentDate <= getFilterStatus.expiryDate) {
            setStartDate(new Date(getFilterStatus.startDate));
            setEndDate(new Date(getFilterStatus.endDate));
            setDonationType(getFilterStatus.donationType);
            setPayStatus(getFilterStatus.payStatus);
            setOnlyBank(getFilterStatus.onlyBank);
        }
        setUserInfo(getUserInfo);
        if (getUserInfo) {
            getUserInfo.UserPermissionList.forEach((r) => {
                if (r === '/add-donation') {
                    setPermissonAdd(true);
                } else if (r === '/update-donation') {
                    setPermissonUpdate(true);
                } else if (r === '/delete-donation') {
                    setPermissonDelete(true);
                } else if(r === "/order-group-number") {
                    setPermissonGroupNo(true);
                }
            })
        }
        let col = []
        let select = []
        columnsData.map(item => {
            col.push({ name: item.header, code: item.field });
            select.push(item.field);
        });
        setOptionsColumn(col);
        const localSelectColumn = localStorage.getItem("selectColumn");
        if(localSelectColumn && JSON.parse(localSelectColumn).length > 0) {
            setSelectColumn(JSON.parse(localSelectColumn));
        } else {
            setSelectColumn(select);
        }

        Remotes.getDonationTypeForList().then(r => {
            if (r.status === 200 && r.data) {
                debugger
                let optionDonationTypes = [];
                let optionDonationType = [{ label: 'Tümü', value: 0 }];

                r.data.forEach(item => {
                    optionDonationTypes.push({ label: item.DonationTitle, value: {ID: item.ID, DonationKindID: item.DonationKindID, Amount: item.Amount} })
                    optionDonationType.push({ label: item.DonationTitle, value: item.ID })
                });
                setOptionDonationTypes(optionDonationTypes);
                setOptionDonationType(optionDonationType);
            }
        })

        buttonFilter();
    }, [])

    useEffect(()=> {
        if(selectColumn) {
            localStorage.setItem("selectColumn", JSON.stringify(selectColumn));
            let column = [<Column key={1} selectionMode="multiple" headerStyle={{ width: '1em' }} />];
            columnsData.map((item, ind) => {
                if(selectColumn.includes(item.field)) {
                    column.push(<Column key={ind} field={item.field} header={item.header} body={item?.body} sortable filter ></Column>)
                }
            });
            column.push(<Column key={18} body={actionBodyTemplate} exportable={false} style={{ minWidth: '15rem' }}></Column>);
            setSelectedColumns(column)
        }

    }, [selectColumn])

    const buttonFilter = () => {
        setLoadingFilter(true);
        
        let response = {
            StartDate: `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`,
            EndDate: `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`,
            DonationTypeId: donationType ? donationType : null,
            IsPay: payStatus,
            PlaceOfPayment: onlyBank
        }
        Remotes.getDonationList(response).then(r => {
            if (r.status === 200) {
                setDataDonations(r.data);
            }
            setLoadingFilter(false);

        }).catch(() => setLoadingFilter(false))
    }
    const exportColumns = colsExport.map(col => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, dataDonations);
                doc.save('Bagislar.pdf');
            })
        })
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            var records = dataDonations.map(item => {
                return {
                    AdSoyad: item.DonorNameSurname,
                    Telefon: item.DonorPhoneNumber,
                    EMail: item.DonorEmail,
                    BagisTipi: item.DonationType,
                    Adet: item.DonotionQuantity,
                    Tutar: item.Amount,
                    BaşkasınınAdınaAdSoyad: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.NameSurname : null,
                    BaşkasınınAdınaTelefon: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.PhoneNumber : null,
                    BaşkasınınAdınaEPosta: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.DonorEmail : null,
                    GrupNo: item.GroupNo,
                    OdemeDurumu: item.IsPay ? 'Aktif' : 'Pasif',
                    RefCode: item.RefCode,
                    OdemeKodu: item.DonationCode,
                    EkleyenKullanici: item.AddedUserNameSurname,
                    KayitTarihi: item.CreatedDate
                }
            })
            const worksheet = xlsx.utils.json_to_sheet(records);

            const workbook = { Sheets: { 'bagislar': worksheet }, SheetNames: ['bagislar'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'bagislar');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const addDonationErrorControl = () => {
        debugger
        let error = true;
        if (!newName) {
            error = false;
        }
        if (!isPhone.test(newPhone)) {
            error = false;
        }
        if (!isMail.test(newEPosta)) {
            error = false;
        }
        if (!newDonationType) {
            error = false;
        }
        if (newDonationType && newDonationType.DonationKindID === 2 && !newQuatity) {
            error = false;
        }
        if (newDonationType && newDonationType.DonationKindID === 1 && !newAmount) {
            error = false;
        }
        if (newOtherDonation) {
            if (!newOtherName) {
                error = false;
            }
            if (!isPhone.test(newOtherPhone)) {
                error = false;
            }
            if (!isMail.test(newOtherEPosta)) {
                error = false;
            }
        }

        return error;
    }

    const addDonationButton = () => {
        if (addDonationErrorControl()) {
            setLoadingAddDonation(true);
            let response = {
                UserID: JSON.parse(localStorage.getItem("User")).UserId,
                DonorNameSurname: newName.trim(),
                DonorPhoneNumber: newPhone,
                DonorEmail: newEPosta.trim(),
                DonationTypeID: newDonationType.ID,
                DonationQuantity: newDonationType.DonationKindID === 1 ? 1 : newQuatity,
                TotalAmount: newDonationType.DonationKindID === 1 ? newAmount : null,
                IsOtherDonation: newOtherDonation,
                OtherNameSurname: newOtherDonation ? newOtherName.trim() : null,
                OtherNumberPhone: newOtherPhone ? newOtherPhone : null,
                OtherEmail: newOtherEPosta ? newOtherEPosta.trim() : null,
            }
            Remotes.saveDonationByAdmin(response).then(r => {
                if (r.status === 200) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                    setNewName('');
                    setNewPhone('');
                    setNewEPosta('');
                    setNewDonationType(null);
                    setNewOtherDonation(false);
                    setNewOtherName('');
                    setNewOtherPhone('');
                    setNewOtherEPosta('');
                    setNewQuatity(1);

                    setDisplayDialogDonation(false);
                    buttonFilter();

                }
                setLoadingAddDonation(false);
            }).catch(() => setLoadingAddDonation(false));
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Zorunlu alanları giriniz', life: 3000 });
        }
    }

    const videoDelete = () => {
        if (selectedRow?.VideoLink) {
            let response = {
                DonationID: selectedRow.ID
            }
            Remotes.removeVideo(response).then(r => {
                if (r.status === 200) {
                    confirmDialog({
                        message: 'Video Silme Başarılı',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                    buttonFilter();
                }
            })

        }

    }

    const changeCallStatus = () => {

        let response = {
            DonationID: selectedRow.ID
        }
        Remotes.changeCall(response).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Durum Değişti',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                buttonFilter();
                setSelectedRow({ ...selectedRow, DonationStatus: selectedRow.DonationStatus === 1 ? 2 : 1 })
            }

        })

    }

    const cardFooter = (
        <div>
            <Button label="Güncelle" icon="pi pi-check" loading={loadingUpdate} onClick={donationUpdate} />
            <Button label="Sil" icon="pi pi-times" className="p-button-danger" onClick={() => deleteCategory(selectedRow)} />
            {
                selectedRow?.VideoLink ?
                    <Button label="Video Sil" icon="pi pi-times" className="p-button-warning" onClick={videoDelete} />
                    : null
            }
            {
                selectedRow?.DonationStatus === 1 ?
                    <Button label="Arandı" icon="pi pi-phone" className="p-button-info" onClick={changeCallStatus} />
                    : selectedRow?.DonationStatus === 2 ?
                        <Button label="Aranmadı" icon="pi pi-minus" className="p-button-info" onClick={changeCallStatus} />
                        : null
            }
        </div>
    );

    const cardAddDonationFooter = (
        <div>
            <Button label="Ekle" icon="pi pi-check" loading={loadingAddDonation} onClick={addDonationButton} />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={() => console.log("temizle")} />
        </div>
    );

    const cardGroupNoFooter = (
        <div>
            <Button label="Sıfırla" className="p-button-danger"loading={loadingAddDonation} onClick={(event)=>{
                confirmPopup({
                    target: event.currentTarget,
                    message: 'Seçilen bağışların grup numarası yeniden sıralanacaktır. Gruplamayı sıfırlamak istediğinizden emin misiniz? ',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Evet',
                    rejectLabel: 'Hayır',
                    accept: () => {accept()}
                });
            }} />
        </div>
    );

    const accept = () => {
        const response = {
            Year: parseInt(groupYear),
            SubGroup: groupType
        }
        Remotes.setGroupNo(response).then(r=>{
            if(r.status === 200) {
                confirmDialog({
                    message: `Başarılı bir şekilde gruplandı.`,
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                buttonFilter();
            }
        })

    }

    const onGlobalFilterChange = (event, filtersKey) => {
        const value = event.target.value;
        let filters = { ...filtersMap[filtersKey].value };
        filters['global'].value = value;
        // filters['global'].constraints[0].value = value.toLocaleLowerCase('TR');
        // filters['global'].constraints[1].value = value.toLocaleUpperCase('TR');
        // setGlobalSearch(value);
        // setFilters2(globalFilterObj(value));

        filtersMap[filtersKey].callback(filters);
    }

    const filtersMap = {
        'filters2': { value: filters2, callback: setFilters2 }
    };

    const header = (
        <div className="flex justify-content-between export-buttons">
            <div className='flex'>
                <span className="p-input-icon-left mr-5">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalSearch} onChange={(e) => onGlobalFilterChange(e, 'filters2')} placeholder="Arama..." />
                </span>
                <MultiSelect className='w-full' options={optionsColumn} value={selectColumn} onChange={r => setSelectColumn(r.value)} optionLabel="name" optionValue='code' maxSelectedLabels={3} placeholder="Seçiniz..." />
            </div>
            <div>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger mr-2" data-pr-tooltip="PDF" />
            </div>
        </div>
    );


    return (
        <div>
            <Toast ref={toast} position="top-right" />
            <div className='flex justify-content-between' style={{ height: 50 }}>
                <h2 className="page-header mt-auto mb-auto">Bağışlar</h2>
                <div>
                {
                    permissonAdd ?
                        <Button className="p-button-info addDonationButton" label="Bağış Ekle" onClick={() => {
                            if (userInfo?.Email) setNewEPosta(userInfo?.Email)
                            setNewName(null);
                            setNewPhone(null);
                            setNewDonationType(null);
                            setNewQuatity(1);
                            setNewAmount(null);
                            setNewOtherName(null);
                            setNewOtherPhone(null);
                            setNewOtherEPosta(null);
                            setNewOtherDonation(false);
                            setDisplayDialogDonation(true);
                        }} />
                        : null
                }
                {
                    permissonGroupNo ?
                        <Button className="p-button-danger ml-5" label="Grup No Sıfırla"  onClick={() => setDisplayDialogGrupNo(true)} />
                        : null
                }
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Bağış Türü:</label>
                                    <Dropdown className='w-full' options={optionDonationType} value={donationType} onChange={r => { setDonationType(r.value) }} optionLabel="label" placeholder="Seçiniz..." />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Ödeme Durumu:</label>
                                    <Dropdown className='w-full' disabled={onlyBank} options={[{ label: 'Aktif', value: true }, { label: 'Pasif', value: false }]} value={payStatus} onChange={r => { setPayStatus(r.value) }} optionLabel="label" placeholder="Seçiniz..." />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-4">
                                    <label className='mr-5 block'>Başlangıç Tarihi:</label>
                                    <Calendar className='w-full' showIcon dateFormat='dd/mm/yy' value={startDate} onChange={e => { setStartDate(e.value) }} ></Calendar>
                                </div>
                                <div className="field col-12 md:col-12 lg:col-4">
                                    <label className='mr-5 block'>Bitiş Tarihi:</label>
                                    <Calendar className='w-full' showIcon dateFormat='dd/mm/yy' value={endDate} onChange={e => { setEndDate(e.value) }} ></Calendar>
                                </div>
                                <div className="field col-12 md:col-12 lg:col-4 m-auto">
                                    <label className='ml-2 w-full' >Sadece Bankadan</label>
                                    <InputSwitch checked={onlyBank} onChange={(e) => setOnlyBank(e.value)}  ></InputSwitch>
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-3">
                                    <Button className="p-button-info w-full" label="Filtre" loading={loadingFilter} onClick={()=> {
                                        const filterStatus = {
                                            startDate,
                                            endDate,
                                            donationType,
                                            payStatus,
                                            onlyBank,
                                            expiryDate: new Date().getDate()
                                        };
                                        localStorage.setItem("filterStatus", JSON.stringify(filterStatus));
                                        buttonFilter();
                                        
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table ref={dt} columns={selectedColumns} header={header} value={dataDonations}
                                globalFilterFields={['DonorNameSurname', 'DonorPhoneNumber', 'DonorEmail', 'DonationType', 'Amount', 'DonationType', 'OtherDonationInfoVM.NameSurname', 'OtherDonationInfoVM.PhoneNumber', 'OtherDonationInfoVM.DonorEmail', 'DonotionQuantity', 'IsPay', 'RefCode', 'GroupNo', 'DonationCode', 'VideoLink', 'AddedUserNameSurname', 'CreatedDate']}
                                filters={filters2} onFilter={(e) => setFilters2(e.filters)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Bağış Düzenle" visible={displayDialog} onHide={onHide} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={cardFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Ad Soyad</label>
                        <InputText id="in" className='w-full' value={updateName} onChange={e => {
                            setUpdateName(e.target.value)
                        }} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Telefon</label>
                        <PhoneInput country={'tr'} countryCodeEditable={false} className='w-full' value={updatePhone} onChange={e => {
                            setUpdatePhone(e)
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>E-Posta</label>
                        <InputText id="in" className='w-full' value={updateMail} onChange={e => {
                            setUpdateMail(e.target.value)
                        }} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Grup No</label>
                        <InputText id="in" className='w-full' disabled={selectedRow && !selectedRow.DonationTypeInfo.IsHisse} value={updateGroupNo} onChange={e => {
                            setUpdateGroupNo(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Bağış Türü</label>
                        <Dropdown className='w-full' options={optionDonationTypes} optionLabel="label"
                            placeholder="Seçiniz..." value={updateDonationType} onChange={e => setUpdateDonationType(e.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Tutar</label>
                        <InputText id="in" className='w-full' value={updateAmount} onChange={e => {
                            const re = /^[0-9\b]+$/;
                            if (re.test(e.target.value) || !e.target.value) {
                                setUpdateAmount(e.target.value)
                            }
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Video/Sertifika</label>
                        <FileUpload customUpload chooseLabel={selectedUpdateImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                            setSelectedUpdateImage(null);
                        }} uploadHandler={e => {
                            setSelectedUpdateImage(e.files);
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <Checkbox inputId="binary" className="m-auto" checked={updateOtherDonation} onChange={e => setUpdateOtherDonation(e.checked)} />
                        <label htmlFor="binary" className="m-auto pl-2">Başkasının Adına</label>
                    </div>
                </div>
                {
                    updateOtherDonation ?
                        <>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Ad Soyad</label>
                                    <InputText id="in" className='w-full' value={updateOtherName} onChange={e => setUpdateOtherName(e.target.value)} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Telefon</label>
                                    <PhoneInput country={'tr'} countryCodeEditable={false} className='w-full' value={updateOtherPhone} onChange={e => setUpdateOtherPhone(e)} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>E-Posta</label>
                                    <InputText id="in" className='w-full' value={updateOtherEPosta} onChange={e => setUpdateOtherEPosta(e.target.value)} />
                                </div>
                            </div>
                        </> : null
                }
                {
                    loadVideo > 0 ?
                        <ProgressBar value={loadVideo}></ProgressBar>
                        : null
                }

            </Dialog>
            <Dialog header="Bağış Video" visible={displayDialogVideo} onHide={onHideVideo} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }}  >
                <div className="container">
                    {
                        linkType && linkType.indexOf('video') !== -1 ?
                            <video height={350} controls src={API_ENDPOINTS.BASE_URL + '/' + (link ? link : null)} />
                            : linkType && linkType.indexOf('image') !== -1 ?
                                <img alt='Bagiş Resim' src={API_ENDPOINTS.BASE_URL + '/' + (link ? link : null)} height={500} />
                                : <p>Video veya Fotoğraf Yüklenmemiştir</p>

                    }

                </div>
            </Dialog>
            <Dialog header="Bağış Ekle" visible={displayDialogDonation} onHide={onHideDonation} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={cardAddDonationFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Ad Soyad</label>
                        <InputText id="in" className='w-full' value={newName} onChange={e => setNewName(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Telefon</label>
                        <PhoneInput country={'tr'} countryCodeEditable={false} className='w-full' value={newPhone} onChange={e => setNewPhone(e)} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>E-Posta</label>
                        <InputText id="in" className='w-full' value={newEPosta} onChange={e => setNewEPosta(e.target.value.replaceAll(' ', ''))} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Bağış Türü</label>
                        <Dropdown className='w-full' options={optionDonationTypes}
                            placeholder="Seçiniz..." value={newDonationType} onChange={e => {
                                setNewDonationType(e.value);
                                if (e.value.DonationKindID === 1) {
                                    setNewQuatity(1);
                                }
                            }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Adet</label>
                        <InputNumber className="input-count w-100" showButtons min={1} value={newQuatity} disabled={newDonationType && newDonationType.DonationKindID === 1} onChange={e => {
                            const re = /^[0-9\b]+$/;
                            if (re.test(e.value)) {
                                setNewQuatity(e.value)
                            }
                        }} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Bağış Tutarı:</label>
                        <InputNumber id="in" className='w-full' mode="currency" currency="TRY" locale="tr-TR" disabled={newDonationType && newDonationType.DonationKindID === 2} value={newDonationType && newDonationType.DonationKindID === 2 ? newDonationType.Amount * newQuatity : newAmount} onChange={r => {
                            const re = /^[0-9\b]+$/;
                            if (re.test(r.value) || !r.value) {
                                setNewAmount(r.value);
                            }
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <Checkbox inputId="binary" className="m-auto" checked={newOtherDonation} onChange={e => setNewOtherDonation(e.checked)} />
                        <label htmlFor="binary" className="m-auto pl-2">Başkasının Adına</label>
                    </div>
                </div>
                {
                    newOtherDonation ?
                        <>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Ad Soyad</label>
                                    <InputText id="in" className='w-full' value={newOtherName} onChange={e => setNewOtherName(e.target.value)} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Telefon</label>
                                    <PhoneInput country={'tr'} countryCodeEditable={false} className='w-full' value={newOtherPhone} onChange={e => setNewOtherPhone(e)} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>E-Posta</label>
                                    <InputText id="in" className='w-full' value={newOtherEPosta} onChange={e => setNewOtherEPosta(e.target.value)} />
                                </div>
                            </div>
                        </> : null
                }
            </Dialog>
            <Dialog header="Grup No Sıfırla" visible={displayDialogGrupNo} onHide={onHideGrupNo} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={cardGroupNoFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Yıl</label>
                        <InputText className='w-full' value={groupYear} onChange={(e) => setGroupYear(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Gruplama</label>
                        <Dropdown className='w-full' options={subGroupOptions}
                            placeholder="Seçiniz..." value={groupType} onChange={e => {
                                setGroupType(e.value);
                            }} />
                    </div>
                </div>
            </Dialog>
        </div>
    ) 
}

export default Donations
