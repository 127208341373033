import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './sidebar.css'
import logo from '../../assets/images/logo.svg'
import sidebar_items from '../../assets/JsonData/sidebar_routes.json'
import { useState } from 'react'
import Action from '../../redux/actions/Action'


const SidebarItem = props => {

    const active = props.active ? 'active' : ''

    return (
        <div className="sidebar__item">
            <div className={`sidebar__item-inner ${active}`}>
                <i className={props.icon}></i>
                <span>
                    {props.title}
                </span>
            </div>
        </div>
    )
}

const Sidebar = props => {
    const user = JSON.parse(localStorage.getItem('User'));
    const activeItem = sidebar_items.findIndex(item => item.route === props.location.pathname)

    const isOpen = useSelector(state => state.Reducer.openSideBar)
    const dispacth = useDispatch();

    const isOpenClick = () => {
        dispacth(Action.openSideBar(false))
    }

    return (
        <div className={isOpen ? 'sidebar open': 'sidebar'} >
            <div className="sidebar__logo" onClick={()=>window.location.href = '/'}>
                <img src={logo} alt="company logo" />
            </div>
            <div style={{overflowY: 'auto', height:'90%', paddingBottom: '100px'}}>
                {
                    sidebar_items.map((item, index) => (
                        user && user.UserPermissionList && user.UserPermissionList.includes(item.route) ?
                        <div onClick={isOpenClick} key={index}><Link to={item.route} key={index} >
                            <SidebarItem
                                title={item.display_name}
                                icon={item.icon}
                                active={index === activeItem}
                            />
                        </Link></div> : null
                    ))
                }
                
            </div>
            {/* <div  className='logout' style={{backgroundColor: '#FFF'}} onClick={()=> window.location.href='/logout'}>
                <SidebarItem
                    title={'Çıkış'}
                    icon={'bx bx-log-out'}
                    active={false}

                />
            </div> */}
            
        </div>
    )
}

export default Sidebar
