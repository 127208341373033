import React, {useState, useEffect} from 'react'
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import Remotes from '../Remotes';
import { confirmDialog } from 'primereact/confirmdialog';
import Table from '../components/table/Table';
import { Dialog } from 'primereact/dialog';
import {API_ENDPOINTS} from "../api";

const Category = () => {
    const[categoryName, setCategoryName] = useState('');
    const [category, setCategory] = useState();
    const[selectedImage, setSelectedImage] = useState();
    const[saveLoading, setSaveLoading] = useState(false);
    const[selectedUpdateImage, setSelectedUpdateImage] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState();

    const [permissonAdd, setPermissonAdd] = useState(false);
    const [permissonUpdate, setPermissonUpdate] = useState(false);
    const [permissonDelete, setPermissonDelete] = useState(false);

    const listCategory = () => {
        Remotes.getCategory().then(r=>{
            if(r.status === 200) {
                setCategory(r.data);
            }
        })
    }

    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('User'));

        if(userInfo) {
            userInfo.UserPermissionList.forEach((r)=>{
                if(r === '/add-category') {
                    setPermissonAdd(true);
                } else if( r=== '/update-category') {
                    setPermissonUpdate(true);
                } else if(r === '/delete-category') {
                    setPermissonDelete(true);
                }
            })
        }

        listCategory();

      }, []);

    const addCategory = () => {
        if(selectedImage) {
        setSaveLoading(true);
        let response = {
            Name: categoryName
        };
        Remotes.postAddCategory(response).then(r=>{
            if(r.status === 200) {
                var bodyFormData = new FormData();
                bodyFormData.append('categoryId', r.data);
                bodyFormData.append('image', selectedImage[0]);

                Remotes.saveCategoryImage(bodyFormData).then(t => {
                    if(t.status === 200) {
                        confirmDialog({
                            message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                            header: 'Başarılı',
                            icon: 'pi pi-check-circle',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',
                        });
                        listCategory();
                    } else {
                        confirmDialog({
                            message: 'Bir Hata Oluştu.',
                            header: 'Hata',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button-danger',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',
                            
                        });
                    }
                    setSaveLoading(false);
                    listCategory();


                })
            } else {
                confirmDialog({
                    message: 'Bir Hata Oluştu.',
                    header: 'Hata',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                    
                });
                setSaveLoading(false);

            }
        }).catch(e=>{
            setSaveLoading(false);

        })
    }else {
        confirmDialog({
            message: 'İkon Yükleyiniz',
            header: 'Hata',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Tamam',
            rejectClassName: 'hidden',
            
        });
    }

    }

    const removeCategory = (selected) => {
        let response = {
            ID: selected.ID
        }
        Remotes.removeCategory(response).then(r=>{
            if(r.status === 200) {
                confirmDialog({
                    message: 'Başarılı Şekilde Silindi',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                listCategory();
            }
        })
    }

    const viewCategory = (selected) => {
        setSelectedRow(selected)
        setDisplayDialog(true);
    }

    const deleteCategory = (selected) => {
        setSelectedRow(selected);
        confirmDialog({
            message: selected.Name + " silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => removeCategory(selected)
            
        });

        setDisplayDialog(false);
        
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {
                    permissonUpdate ?
                        <Button icon="pi pi-pencil" className="p-button p-button-info mr-2" onClick={() => viewCategory(rowData)} />
                        : null
                }
                {
                    permissonDelete ?
                        <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => deleteCategory(rowData)} />
                        : null
                }
            </React.Fragment>
        );
    }

    const columns = [
        <Column rowReorder style={{width: '3em'}} />,
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>,
        <Column field="Name" header="Kategori Adı" sortable filter filterPlaceholder="Değer giriniz..." />,
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
    ]

    const updateCategory = () => {

        let response = {
            ID: selectedRow.ID,
            CategoryName: selectedRow.Name
        }

        Remotes.updateCategory(response).then(r=>{
            if(r.status === 200) {
                if(selectedUpdateImage) {
                    var bodyFormData = new FormData();
                    bodyFormData.append('categoryId', selectedRow.ID);
                    bodyFormData.append('image', selectedUpdateImage[0]);

                    Remotes.updateCategoryImage(bodyFormData).then(t => {
                        if(t.status === 200) {
                            confirmDialog({
                                message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                header: 'Başarılı',
                                icon: 'pi pi-check-circle',
                                acceptLabel: 'Tamam',
                                rejectClassName: 'hidden',
                            });
                        } else {
                            confirmDialog({
                                message: 'Bir Hata Oluştu.',
                                header: 'Hata',
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                acceptLabel: 'Tamam',
                                rejectClassName: 'hidden',
                                
                            });
                        }
                    })
                } else {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                }
            } else {
                confirmDialog({
                    message: 'Bir Hata Oluştu.',
                    header: 'Hata',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                    
                });
            }
            listCategory();
        })
       
    }

    const cleanCategory = () => {
        setCategoryName('');
        setSelectedImage();
        setSaveLoading(false);
    }

    const cardFooter = (
        <div>
            <Button label="Güncelle" icon="pi pi-check" onClick={updateCategory} />
            <Button label="Sil" icon="pi pi-times" className="p-button-danger" onClick={()=> removeCategory(selectedRow)} />
        </div>
    );

    const onHide = () => {
        setDisplayDialog(false);
    }

    const onRowReorder = (e) => {
        let copyData = [...e.value];
        let orderArray = [];
        copyData.forEach(element => {
            orderArray.push(element.ID)
        })
        let response = {
            CategoryID: orderArray
        }
        console.log(response)
        Remotes.orderCategory(response).then(r=>{
            if(r.status === 200) {
                setCategory(e.value);
            }
        })

    }
    
    return (
        <div>
            <h2 className="page-header"> Kategoriler</h2>

            {
                permissonAdd ?

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card__body">
                                    <div className="fluid formgrid grid">
                                        <div className="field col-12 md:col-12 lg:col-6">
                                            <label className='mr-5 block'>Kategori Adı</label>
                                            <InputText id="in" className='w-full' value={categoryName} onChange={r=>{
                                                setCategoryName(r.target.value);
                                            }} />
                                        </div>
                                        <div className="field col-12 md:col-12 lg:col-6">
                                            <label className='mr-5 block'>İkon</label>
                                            <FileUpload   accept="image/*" multiple={false}  customUpload maxFileSize={1000000} chooseLabel={selectedImage ? "Yüklendi" : "Yükle"} auto onRemove={e=>{
                                                setSelectedImage(null);
                                            }} uploadHandler={e=>{
                                                setSelectedImage(e.files);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="fluid formgrid grid">
                                        <div className="field col-12 md:col-12 lg:col-3">
                                            <Button className="p-button-info w-full" label="Kaydet" iconPos="right" loading={saveLoading} onClick={addCategory} />
                                        </div>
                                        <div className="field col-12 md:col-12 lg:col-3">
                                            <Button className="p-button-secondary w-full" label="Temizle" onClick={cleanCategory} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table columns={columns} reorderableColumns onRowReorder={onRowReorder}  value={category}/>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Bağış Düzenle" visible={displayDialog} onHide={onHide} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} footer={cardFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Kategori Adı</label>
                        <InputText id="in" className='w-full' value={selectedRow && selectedRow.Name ? selectedRow.Name : ''} onChange={r=>{
                            let copySelected = {...selectedRow};
                            copySelected.Name = r.target.value;
                            setSelectedRow(copySelected);
                        }} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>İkon</label>
                        <FileUpload   accept="image/*"  customUpload chooseLabel={selectedUpdateImage ? "Yüklendi" : "Yükle"} auto onRemove={e=>{
                            setSelectedUpdateImage(null);
                        }} uploadHandler={e=>{
                            setSelectedUpdateImage(e.files);
                        }} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>İkon Önizleme</label>
                        <img className='w-full' key={selectedRow ? selectedRow.ID : 0} src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_CATEGORY_IMAGE+(selectedRow && selectedRow.ID)}/>
                    </div>
                    
                </div>
            </Dialog>
        </div>
    )
}

export default Category