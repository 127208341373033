const initialState = {
    openSideBar: false,
    user: null
}
const Reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_MODE':
            return {
                ...state,
                mode: action.payload
            }
        case 'SET_COLOR':
            return {
                ...state,
                color: action.payload
            }
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload
            }
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SELECTED_DONATION':
            return {
                ...state,
                selectedDonation: action.payload
            }
        case 'OPEN_SIDEBAR':
            return {
                ...state,
                openSideBar: action.payload
            }
        default:
            return state
    }
}

export default Reducer