import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Captcha } from 'primereact/captcha';
import backImage from '../assets/images/bg-01.jpg';
import Remotes from '../Remotes';
import Action from '../redux/actions/Action';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    // useEffect(() => {
    //     const loadScriptByURL = (id, url, callback) => {
    //       const isScriptExist = document.getElementById(id);
    
    //       if (!isScriptExist) {
    //         var script = document.createElement("script");
    //         script.type = "text/javascript";
    //         script.src = url;
    //         script.id = id;
    //         script.onload = function () {
    //           if (callback) callback();
    //         };
    //         document.body.appendChild(script);
    //       }
    
    //       if (isScriptExist && callback) callback();
    //     }
    
    //     loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA}`, function () {
    //       console.log("Script loaded!");
    //     });
    //   }, []);

    const loginBtn = (e) => {
        e.preventDefault();
        setLoading(true);
        let response = 'grant_type=password&username='+username+'&password='+password;
        Remotes.postLogin(response).then(r => {
            dispatch(Action.setToken(r.data.access_token));
            sessionStorage.setItem("token", r.data.access_token);
            let res = { Username: username,Password: password };
            Remotes.postUser(res).then(t => {
                if(t && t.status === 200) {
                    t.data.UserPermissionList.push('/');
                    t.data.UserPermissionList.push('/logout');
                    dispatch(Action.setUser(t.data));
                    localStorage.setItem('User', JSON.stringify(t.data));
                    history.push('/');
                }
                setLoading(false);
            }).catch(()=> setLoading(false))
        }).catch(()=> setLoading(false));

    }

    const showResponse = (e) => {
        console.log('denemeee', e)
        
    }
    

    return (
        <div className="limiter">
            <div className="container-login100" style={{backgroundImage: `url(${backImage})` }}>

                <div className="wrap-login100 p-5">
                    <form className="login100-form" onSubmit={loginBtn}>
                        <span className="login100-form-title pb-4">Panel Giriş </span>
                            <span className="p-float-label ">
                                <InputText id="username" className='w-full' onChange={e=>{
                                    setUsername(e.target.value)
                                }}  />
                                <label htmlFor="username">Kullanıcı Adı</label>
                            </span>
                            <span className="p-float-label mt-5">
                                <Password  id="parola" className='w-full' toggleMask feedback={false}  onChange={e=>{
                                    setPassword(e.target.value);
                                }} />
                                <label htmlFor="parola">Parola</label>
                            </span>
                            <div className="text-right pt-2">
                                <a href="#"> Şifremi Unuttum </a>
                            </div>
                            {/* <span className="p-float-label my-2" >
                                <Captcha siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA} onResponse={showResponse} />
                            </span> */}
                        <Button className="w-full" loading={loading} label="Giriş Yap" type="submit" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
