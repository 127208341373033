import React, {useState, useEffect} from 'react'
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import Table from '../components/table/Table';
import Remotes from '../Remotes';
import { confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';



const PaymentControl = () => {
    const [donationCodes, setDonationCodes] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    const dateTemplate =(rowData, column)=>{
        let date = new Date(rowData.CreatedDate)
        return date.toLocaleString();
    }

   const booleanChecker = (rowData, item) => {
        if (typeof rowData[item.field] === "boolean") {
        return rowData[item.field] ? "Aktif" : "Pasif";
        } else {
        return rowData[item.field];
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            rowData.ID ?
            <React.Fragment>
                <Button icon="pi pi-dollar" className="p-button p-button-info mr-2"  onClick={()=>onPaymentConfirm(rowData)} />
            </React.Fragment>
            : null
        );
    }

    const accept = (rowData) => {
        let response = {
            ID: rowData.ID
        }
        Remotes.paymentActive(response).then((r)=>{
            if(r.status === 200) {
                confirmDialog({
                    message: 'Bağış ödemesi alındı olarak değiştirildi.',
                    header: 'Başarılı',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                controlButton();
            }
        })
    };


    const onPaymentConfirm = (rowData) => {
        confirmDialog({
            message: 'Bağış Türünü aktife çekmek istediğinizden emin misiniz?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()=>accept(rowData),
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır'
        });
    }

    const columns = [
        <Column key={2} field="DonorNameSurname" header="Ad Soyad" sortable filter  ></Column>, 
        <Column key={3} field="DonorPhoneNumber" header="Telefon No"  sortable filter ></Column>, 
        <Column key={4} field="DonorEmail" header="E-Posta" sortable filter   ></Column>, 
        <Column key={5} field="DonationType" header="Bağış Türü" sortable filter ></Column>, 
        <Column key={6} field="Amount" header="Tutar"  sortable filter ></Column>, 
        <Column key={7} field="OtherDonationInfoVM.NameSurname" header="Başkasının Adına Ad Soyad" sortable filter   ></Column>, 
        <Column key={8} field="OtherDonationInfoVM.PhoneNumber" header="Başkasının Adına Telefon" sortable filter  ></Column>, 
        <Column key={9} field="OtherDonationInfoVM.DonorEmail" header="Başkasının Adına E-Posta" sortable filter  ></Column>, 
        <Column key={10} field="DonotionQuantity" header="Paket Adeti" sortable filter  ></Column>, 
        <Column key={11} field="IsPay" header="Ödeme Durumu" body={booleanChecker} sortable filter ></Column>,
        //<Column key={18} field="VideoLink" header="Kanıt" body={statusChecker} sortable filter ></Column>,

        <Column key={12} field="RefCode" header="Referans Kodu"  sortable filter  ></Column>, 
        <Column key={13} field="GroupNo" header="Grup No" sortable filter ></Column>, 
        <Column key={14} field="DonationCode" header="Ödeme Kodu" sortable filter  ></Column>, 
        <Column key={15} field="VideoLink" header="Video Link" sortable filter  ></Column>, 
        <Column key={16} field="AddedUserNameSurname" header="Ekleyen Kullanıcı" sortable filter  ></Column>, 
        <Column key={17} field="CreatedDate" header="Kayıt Tarihi" body={dateTemplate} sortable filter ></Column>,
        <Column key={18} body={actionBodyTemplate} exportable={false} style={{ minWidth: '15rem' }}></Column>
    ]

    const controlButton = () => {
        setLoading(true);
        let splitCodes = donationCodes.split('\n');
        if(splitCodes.length > 0) {
            Remotes.paymentControl({OrderCodeList: splitCodes}).then(r=>{
                if(r.status === 200) {
                    if(r.data.length >0) {
                        setData(r.data)

                    }else {
                        confirmDialog({
                            message: 'Bütün kayıtların ödemeleri başarılı',
                            header: 'Başarılı',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',
                        });
                    }
                }
                setLoading(false);
            }).catch(()=> setLoading(false))
        }



    }
    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            var records = data.map(item => {
                return {
                    AdSoyad: item.DonorNameSurname,
                    Telefon: item.DonorPhoneNumber,
                    EMail: item.DonorEmail,
                    BagisTipi: item.DonationType,
                    Adet: item.DonotionQuantity,
                    Tutar: item.Amount,
                    BaşkasınınAdınaAdSoyad: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.NameSurname : null,
                    BaşkasınınAdınaTelefon: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.PhoneNumber : null,
                    BaşkasınınAdınaEPosta: item.OtherDonationInfoVM ? item.OtherDonationInfoVM.DonorEmail : null,
                    GrupNo: item.GroupNo,
                    OdemeDurumu: item.IsPay ? 'Aktif' : 'Pasif',
                    RefCode: item.RefCode,
                    OdemeKodu: item.DonationCode,
                    EkleyenKullanici: item.AddedUserNameSurname,
                    KayitTarihi: item.CreatedDate
                }
            })
            const worksheet = xlsx.utils.json_to_sheet(records);

            const workbook = { Sheets: { 'bagislar': worksheet }, SheetNames: ['bagislar'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'bagislar');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const header = (
        <div className="flex justify-content-end export-buttons">
            <div>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
            </div>
        </div>
    );
    
    return (
        <div>
            <h2 className="page-header"> Ödeme Kontrol</h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Ödeme Kodu</label>
                                    <InputTextarea id="in" className='w-full' value={donationCodes} onChange={r=>{
                                        setDonationCodes(r.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <Button className='p-button-info' label="Kontrol Et" loading={loading} onClick={controlButton}   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table columns={columns} header={header} reorderableColumns value={data}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentControl