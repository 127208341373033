import RestConstants from "./RestConstants";
import { confirmDialog } from 'primereact/confirmdialog';
import { API_ENDPOINTS } from "./api";


let Remotes = {

  postLogin: async (response) => {
    const url = API_ENDPOINTS.TOKEN_URL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      if (err && err.response.data.error_description) {
        confirmDialog({
          message: err.response.data.error_description,
          header: 'Hata',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Tamam',
          accept: () => { sessionStorage.clear(); },
          rejectClassName: 'hidden',
        });
      }
    }

  },
  postUser: async (response) => {

    const url = API_ENDPOINTS.USER_URL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  postAddDonationType:async (response) => {
    const url = API_ENDPOINTS.ADD_DONATION_TYPE;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  postAddCategory:async (response) => {
    const url = API_ENDPOINTS.ADD_CATEGORY;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getCategory:async () => {
    const url = API_ENDPOINTS.CATEGORY_LIST;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getDonation:async () => {
    const url = API_ENDPOINTS.DONATIONS;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getDonationType:async () => {
    const url = API_ENDPOINTS.DONATION_TYPE;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getDonationTypeForList:async () => {
    const url = API_ENDPOINTS.DONATION_TYPE_FOR_LIST;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  removeDonation:async (response) => {
    const url = API_ENDPOINTS.REMOVE_DONATION;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateDonationType:async (response) => {
    const url = API_ENDPOINTS.UPDATE_DONATION_TYPE;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateDonationImage:async (response) => {
    const url = API_ENDPOINTS.UPDATE_DONATION_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateProjectImage:async (response) => {
    const url = API_ENDPOINTS.UPDATE_DONATION_PROJECT_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonationImage:async (response) => {
    const url = API_ENDPOINTS.DONATION_SAVE_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonationProjectImage:async (response) => {
    const url = API_ENDPOINTS.DONATION_SAVE_PROJECT_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveCategoryImage:async (response) => {
    const url = API_ENDPOINTS.CATEGORY_SAVE_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  removeCategory:async (response) => {
    const url = API_ENDPOINTS.REMOVE_CATEGORY;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateCategory:async (response) => {
    const url = API_ENDPOINTS.UPDATE_CATEGORY;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateCategoryImage:async (response) => {
    const url = API_ENDPOINTS.CATEGORY_UPDATE_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getDonationList: async (response) =>{
    const url = API_ENDPOINTS.GET_DONATION_LIST;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonationVideo: async (response, onLoad) =>{
    const url = API_ENDPOINTS.SAVE_DONATION_VIDEO;
    try {
      const r = await RestConstants.jPost(url, response, onLoad);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateDonation: async (response) =>{
    const url = API_ENDPOINTS.UPDATE_DONATION;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  smsVideo: async (response) =>{
    const url = API_ENDPOINTS.SMS_VIDEO;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  groupNoVideo: async (response,onLoad) =>{
    const url = API_ENDPOINTS.SAVE_GROUP_NO_VIDEO;
    try {
      const r = await RestConstants.jPost(url, response, onLoad);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getDashboard: async () =>{
    const url = API_ENDPOINTS.GET_DASHBOARD;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteDonation: async (response) =>{
    const url = API_ENDPOINTS.DELETE_DONATION;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  orderCategory: async (response) =>{
    const url = API_ENDPOINTS.ORDER_CATEGORY;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonationByAdmin: async (response) =>{
    const url = API_ENDPOINTS.ADD_DONATION;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  setGroupNo: async (response) =>{
    const url = API_ENDPOINTS.ORDER_GROUP_NO;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getUserList: async () =>{
    const url = API_ENDPOINTS.GET_USERS;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getPermissionList: async () =>{
    const url = API_ENDPOINTS.GET_PERMISSION;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  addUser: async (response) =>{
    const url = API_ENDPOINTS.ADD_USERS;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteUser: async (response) =>{
    const url = API_ENDPOINTS.DELETE_USER;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateUser: async (response) =>{
    const url = API_ENDPOINTS.UPDATE_USER;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getImage: async (response) =>{
    const url = response.url
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getTab: async () =>{
    const url = API_ENDPOINTS.GET_TAB;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  orderTab: async (response) =>{
    const url = API_ENDPOINTS.ORDER_TAB;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteTab: async (response) =>{
    const url = API_ENDPOINTS.DELETE_TAB;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateSlider: async (response) =>{
    const url = API_ENDPOINTS.UPDATE_SLIDER;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteData: async (response) =>{
    const url = API_ENDPOINTS.DELETE_DATA;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getSlider: async () =>{
    const url = API_ENDPOINTS.GET_SLIDER;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  addSlider: async (response) =>{
    const url = API_ENDPOINTS.ADD_SLIDER;
    try {
      const r = await RestConstants.jPost(url,response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteSlider: async (response) =>{
    const url = API_ENDPOINTS.DELETE_SLIDER;
    try {
      const r = await RestConstants.jPost(url,response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  addSliderImage:async (response) => {
    const url = API_ENDPOINTS.ADD_SLIDER_PHOTO;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveNews:async (response) => {
    const url = API_ENDPOINTS.ADD_NEWS;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateNews:async (response) => {
    const url = API_ENDPOINTS.UPDATE_NEWS;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  orderNews:async (response) => {
    const url = API_ENDPOINTS.UPDATE_NEWS_SEQUENCE;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  deleteNews:async (response) => {
    const url = API_ENDPOINTS.DELETE_NEWS;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveNewsImage:async (response) => {
    const url = API_ENDPOINTS.ADD_NEWS_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateNewsImage:async (response) => {
    const url = API_ENDPOINTS.UPDATE_NEWS_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getNews:async () => {
    const url = API_ENDPOINTS.GET_NEWS;
    try {
      const r = await RestConstants.jPost(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonationTabImage:async (response) => {
    const url = API_ENDPOINTS.ADD_DONATION_TAB_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  updateDonationTabImage:async (response) => {
    const url = API_ENDPOINTS.UPDATE_DONATION_TAB_IMAGE;
    try {
      const r = await RestConstants.JImage(url, response, 'post');
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  paymentControl:async (response) => {
    const url = API_ENDPOINTS.PAYMENT_CONTROL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  paymentActive:async (response) => {
    const url = API_ENDPOINTS.PAYMENT_ACTIVE;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  removeVideo:async (response) => {
    const url = API_ENDPOINTS.REMOVE_VIDEO;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  changeCall:async (response) => {
    const url = API_ENDPOINTS.CHANGE_CALL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  orderDonationTypes:async (response) => {
    const url = API_ENDPOINTS.ORDER_DONATION_TYPES;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  orderSlider:async (response) => {
    const url = API_ENDPOINTS.ORDER_SLIDER;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  handleError: (err) => {
    if (err && (err.statusCode === 403 || err.statusCode === 401)) {
      confirmDialog({
        message: 'Giriş süreniz bitti veya buraya girmeye yetkiniz yok. Giriş sayfasına aktarılacaksınız.',
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        accept: ()=>{ sessionStorage.clear(); window.location.href = '/';  },
        rejectClassName: 'hidden',
        
      });

    } else if(err.statusCode === 400) {
      confirmDialog({
        message: err.response.data.Message,
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        rejectClassName: 'hidden',
        
      });

    } else if(err && err.response && err.response.data && err.response.data.error_description) {
      confirmDialog({
        message: err.response.data.error_description,
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        rejectClassName: 'hidden',
        
      });
    } else  {
        confirmDialog({
          message: 'Bir Hata Oluştu.',
          header: 'Hata',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Tamam',
          rejectClassName: 'hidden',
          
      });
    }
    throw err;

  }
};

export default Remotes;
