import React, {useState, useEffect} from 'react'
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import Remotes from '../Remotes';
import { confirmDialog } from 'primereact/confirmdialog';
import Table from '../components/table/Table';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import PhoneInput from 'react-phone-input-2';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

const Users = () => {

    const[userList, setUserList] = useState([]);
    const[permissionList, setPermissionList] = useState([]);
    const[displayAddUser, setDisplayAddUser] = useState(false);
    const[displayUpdateUser, setDisplayUpdateUser] = useState(false);

    const[name, setName] = useState();
    const[surname, setSurname] = useState();
    const[phone, setPhone] = useState();
    const[mail, setMail] = useState();
    const[birthDate, setBirthDate] = useState();
    const[username, setUsername] = useState();
    const[password, setPassword] = useState();
    const[passwordAgain, setPasswordAgain] = useState();
    const[permissionCheck, setPermissionCheck] = useState([]);

    const[userIDUpdate, setUserIDUpdate] = useState(0);
    const[nameUpdate, setNameUpdate] = useState();
    const[surnameUpdate, setSurnameUpdate] = useState();
    const[phoneUpdate, setPhoneUpdate] = useState();
    const[mailUpdate, setMailUpdate] = useState();
    const[birthDateUpdate, setBirthDateUpdate] = useState();
    const[usernameUpdate, setUsernameUpdate] = useState();
    const[passwordUpdate, setPasswordUpdate] = useState();
    const[passwordAgainUpdate, setPasswordAgainUpdate] = useState();
    const[permissionCheckUpdate, setPermissionCheckUpdate] = useState([]);

    const [permissonAdd, setPermissonAdd] = useState(false);
    const [permissonUpdate, setPermissonUpdate] = useState(false);
    const [permissonDelete, setPermissonDelete] = useState(false);




    const dateTemplate =(rowData, column)=>{
        let date = new Date(rowData.CreatedDate)
        return date.toLocaleString();
    }

    const birthDateTemplate =(rowData, column)=>{
        let date = new Date(rowData.BirthDate)
        return date.toLocaleDateString();
    }


   const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            {
                permissonUpdate ? 
                    <Button icon="pi pi-pencil" className="p-button p-button-info mr-2" onClick={() => updateUser(rowData)} />
                    :null
            }
            {
                permissonDelete ?
                    <Button icon="pi pi-trash" className="p-button p-button-danger mr-2" onClick={() => deleteUser(rowData)} />
                    : null
            }
        </React.Fragment>
    );
    }

    const updateUser = (rowData) => {
        setNameUpdate(rowData.Name);
        setSurnameUpdate(rowData.Surname);
        setPhoneUpdate(rowData.PhoneNumber);
        setMailUpdate(rowData.Email);
        setBirthDateUpdate(rowData.birthDate);
        setUsernameUpdate(rowData.Username);
        setUserIDUpdate(rowData.ID)
        setPasswordUpdate(null);
        setPasswordAgainUpdate(null);
        let newData = permissionCheck.filter((item, ind) => {
            if(rowData.UserPermissionList.includes(item.name)) {
                item.check = true;
            }
            else {
                item.check = false;
            }
            return item
        })
        setPermissionCheckUpdate(newData)
        setDisplayUpdateUser(true)

    }

    const deleteUser = (rowData) => {

        confirmDialog({
            message: rowData.Username + " silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => removeUser(rowData.ID)
            
        });
        
        
    }

    const removeUser = (id) =>  {
        let response = {
            UserID: id
        }
        Remotes.deleteUser(response).then(r=> {
            if(r.status === 200) {
                confirmDialog({
                    message: 'Kullanıcı başarılı bir şekilde silindi!',
                    header: 'Kullanıcı Silme',
                    icon: 'pi pi-check',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden'
                });
            }
        })
    }

    const columns = [ 
        <Column key={1} selectionMode="multiple" headerStyle={{ width: '1em' }}/>, 
        <Column key={2} field="Name" header="Ad" sortable filter  />, 
        <Column key={2} field="Surname" header="Soyad" sortable filter  />, 
        <Column key={3} field="PhoneNumber" header="Telefon No"  sortable filter  />, 
        <Column key={4} field="Email" header="E-Posta" sortable filter  />, 
        <Column key={5} field="BirthDate" header="Doğum Tarihi" body={birthDateTemplate} sortable filter />, 
        <Column key={5} field="Username" header="Kullanıcı Adı" sortable filter />, 
        <Column key={11} field="CreatedDate" header="Kayıt Tarihi" body={dateTemplate} sortable filter />,
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '15rem' }}></Column>

    ];

    useEffect(() => {

        let userInfo = JSON.parse(localStorage.getItem('User'));

        if(userInfo) {
            userInfo.UserPermissionList.forEach((r)=>{
                if(r === '/add-user') {
                    setPermissonAdd(true);
                } else if( r=== '/update-user') {
                    setPermissonUpdate(true);
                } else if(r === '/delete-user') {
                    setPermissonDelete(true);
                }
            })
        }

        getUserList();
        getPermissionList();
        
    }, [])

    const getUserList = () => {
        Remotes.getUserList().then(r=> {
            if(r.status === 200) {
                setUserList(r.data)
            }
        })
    }

    const getPermissionList = () => {
        Remotes.getPermissionList().then(r=> {
            if(r.status === 200) {
                setPermissionList(r.data);
                let per = []
                r.data.forEach((p)=> {
                    per.push({
                        id: p.ID,
                        name: p.Name,
                        check: false,
                        description: p.Description
                    });
                    setPermissionCheck(per);
                    setPermissionCheckUpdate(per);
                })
            }

        })
        
    }

    const onHide = () =>{
        setDisplayAddUser(false);
    }

    const onHideUpdate = () => {
        setDisplayUpdateUser(false);
    }

    const addUserButton = () => {
        if(password === passwordAgain) {
            let permissionList= [];

            if(permissionCheck){
                permissionCheck.map(item=>{
                    if(item.check) {
                        permissionList.push(item.id)
                    }
                })
            }
            let response = {
                Name: name,
                Surname: surname,
                BirthDate: birthDate,
                PhoneNumber: phone,
                Email: mail,
                Username : username,
                Password : password,
                PermissionList : permissionList
            }
    
            Remotes.addUser(response).then(r=> {
                if(r.status === 200) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                        
                    });
                    getUserList();
                    onHide();

                }
            })

        } else {
            confirmDialog({
                message: 'Parolalar eşleşmemektedir!',
                header: 'Hata',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',
              });
        }

    }

    const updateUserButton = () => {
        if((passwordUpdate && passwordUpdate === passwordAgainUpdate) || !passwordUpdate) {
            let permissionList= [];

            if(permissionCheckUpdate){
                permissionCheckUpdate.map(item=>{
                    if(item.check) {
                        permissionList.push(item.id)
                    }
                })
            }
            let response = {

                Name: nameUpdate,
                Surname: surnameUpdate,
                PhoneNumber: phoneUpdate,
                Email: mailUpdate,
                BirthDate: birthDateUpdate,
                Username : usernameUpdate,
                Password : passwordUpdate,
                PermissionList : permissionList,
                UserID: userIDUpdate
            }
    
            Remotes.updateUser(response).then(r=> {
                if(r.status === 200) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                        
                    });
                    getUserList();
                    onHideUpdate();

                }
            })

        } else {
            confirmDialog({
                message: 'Parolalar eşleşmemektedir!',
                header: 'Hata',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',
              });
        }

    }

    const changePermisson = (index, value) => {
        let newData = permissionCheck.filter((item, ind) => {
            if( ind === index ) {
                item.check = value
            }
            return item
        })
        setPermissionCheck(newData)
        
    }

    const changePermissonUpdate = (index, value) => {
        let newData = permissionCheckUpdate.filter((item, ind) => {
            if( ind === index ) {
                item.check = value
            }
            return item
        })
        setPermissionCheckUpdate(newData)
        
    }
    

    const cardFooter = (
        <div>
            <Button label="Ekle" icon="pi pi-check" onClick={addUserButton}  />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={()=> console.log("temizle")}  />
        </div>
    );

    const cardUpdateFooter = (
        <div>
            <Button label="Güncelle" icon="pi pi-check" onClick={updateUserButton}  />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={()=> console.log("temizle")}  />
        </div>
    )

    const addUserDialog = () => {
        setName();
        setSurname();
        setPhone();
        setMail();
        setBirthDate();
        setUsername();
        setPassword();
        setPasswordAgain();
        let newData = permissionCheck.filter((item, ind) => {
            item.check = false
            return item
        })
        setPermissionCheck(newData)
        
        setDisplayAddUser(true);
    }
    
    return (
        <div>
            <div className='flex justify-content-between' style={{height: 50}}>
                <h2 className="page-header mt-auto mb-auto">Kullanıcılar</h2>
                {
                    permissonAdd ? 
                        <Button className="p-button-info addDonationButton" label="Kullanıcı Ekle" onClick={()=> addUserDialog() }/>
                        : null
                }
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table columns={columns} value={userList}/>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Kullanıcı Ekle" visible={displayAddUser} onHide={onHide} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} footer={cardFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Ad</label>
                        <InputText id="in" className='w-full' value={name} onChange={(e)=> setName(e.target.value)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Soyad</label>
                        <InputText id="in" className='w-full' value={surname} onChange={(e)=> setSurname(e.target.value)}   />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Telefon</label>
                        <PhoneInput country={'tr'}  countryCodeEditable={false} id="in" className='w-full' value={phone} onChange={(e)=> setPhone(e)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>E-Posta</label>
                        <InputText id="in" className='w-full' value={mail} onChange={(e)=> setMail(e.target.value.replaceAll(' ', ''))} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Doğum Günü Tarihi</label>
                        <Calendar showIcon  dateFormat='dd/mm/yy' id="in"  className='w-full' value={birthDate} onChange={(e)=> setBirthDate(e.value)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Kullanıcı Adı</label>
                        <InputText id="in" className='w-full' value={username} onChange={(e)=> setUsername(e.target.value.replaceAll(' ', ''))}   />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Parola</label>
                        <Password id="in" className='w-full' value={password} onChange={(e)=> setPassword(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Parola Yeniden</label>
                        <Password id="in" className='w-full' value={passwordAgain} onChange={(e)=> setPasswordAgain(e.target.value)}  />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    {
                        permissionCheck && permissionCheck.map((item, index) => {
                            return <div className="field col-12 md:col-12 lg:col-6" key={"keyPermis" + index}>
                            <Checkbox inputId={"binary" + index} className="m-auto" 
                            checked={item.check } onChange={e => changePermisson(index,e.checked)}
                             />
                            <label htmlFor={"binary" + index} className="m-auto pl-2">{item.description ? item.description : item.name }</label>
                        </div>
                        })
                    }
                </div>
            </Dialog>
            <Dialog header="Kullanıcı Düzenle" visible={displayUpdateUser} onHide={onHideUpdate} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} footer={cardUpdateFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Ad</label>
                        <InputText id="in" className='w-full' value={nameUpdate} onChange={(e)=> setNameUpdate(e.target.value)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Soyad</label>
                        <InputText id="in" className='w-full' value={surnameUpdate} onChange={(e)=> setSurnameUpdate(e.target.value)}   />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Telefon</label>
                        <PhoneInput country={'tr'}  countryCodeEditable={false} id="in" className='w-full' value={phoneUpdate} onChange={(e)=> setPhoneUpdate(e)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>E-Posta</label>
                        <InputText id="in" className='w-full' value={mailUpdate} onChange={(e)=> setMailUpdate(e.target.value.replaceAll(' ', ''))} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Doğum Günü Tarihi</label>
                        <Calendar showIcon  dateFormat='dd/mm/yy' id="in"  className='w-full' value={birthDateUpdate} onChange={(e)=> setBirthDateUpdate(e.value)}  />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Kullanıcı Adı</label>
                        <InputText id="in" className='w-full' value={usernameUpdate} onChange={(e)=> setUsernameUpdate(e.target.value.replaceAll(' ', ''))}   />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Parola</label>
                        <Password id="in" className='w-full' value={passwordUpdate} onChange={(e)=> setPasswordUpdate(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Parola Yeniden</label>
                        <Password id="in" className='w-full' value={passwordAgainUpdate} onChange={(e)=> setPasswordAgainUpdate(e.target.value)}  />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    {
                        permissionCheckUpdate && permissionCheckUpdate.map((item, index) => {
                            return <div className="field col-12 md:col-12 lg:col-6" key={"keyPermis" + index}>
                            <Checkbox inputId={"binary" + index} className="m-auto" 
                            checked={item.check } onChange={e => changePermissonUpdate(index,e.checked)}
                             />
                            <label htmlFor={"binary" + index} className="m-auto pl-2">{item.description ? item.description : item.name }</label>
                        </div>
                        })
                    }
                </div>
            </Dialog>
        </div>
    )
}

export default Users