import React, { useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import Remotes from '../Remotes';
import Table from '../components/table/Table';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressBar } from 'primereact/progressbar';


const Video = () => {

    const[selectedVideo, setSelectedVideo] = useState();
    const[groupNo, setGroupNo] = useState("");
    const[groupNoShare, setGroupNoShare] = useState("");
    const[loadingShare, setLoadingShare] = useState(false);
    const[loadingUpLoad, setLoadingUpLoad] = useState(false);
    const [loadVideo, setLoadVideo] = useState(0);

    const [permissonSMS, setPermissonSMS] = useState(false);


    useEffect(() => {

        let userInfo = JSON.parse(localStorage.getItem('User'));

        if(userInfo) {
            userInfo.UserPermissionList.forEach((r)=>{
                if(r === '/sms-video') {
                    setPermissonSMS(true);
                }
            })
        }
    }, [])


    const sendSmsGroupNo = () => {
        if(groupNoShare) {
            setLoadingShare(true);
            let response = {
                GroupNo: groupNoShare
            }
            Remotes.smsVideo(response).then(r=>{
                if(r.status === 200) {
                    confirmDialog({
                        message: 'SMS Gönderildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                }
                setLoadingShare(false);
            }).catch(()=> setLoadingShare(false));
        }

    }

    const smsShare = () =>{
        if(groupNoShare) {
            confirmDialog({
                message: groupNoShare + " Grup No'lu bağışlara sms gönderilsin mi?",
                header: 'SMS Gönder',
                icon: 'pi pi-info-circle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: sendSmsGroupNo
            });
        }
        
    }

    const saveGroupNoVideo = () => {

        if(groupNo && selectedVideo) {
            setLoadingUpLoad(true);
            var bodyFormData = new FormData();
            bodyFormData.append('groupNo', groupNo);
            bodyFormData.append('video', selectedVideo[0]);

            const onLoad = event =>{
                let prog = Math.round((100 * event.loaded) / event.total);
                setLoadVideo(prog)
            }
            Remotes.groupNoVideo(bodyFormData,onLoad).then(r=> {
                if(r.status === 200) {
                    confirmDialog({
                        message: 'Kayıt Başarılı Bir Şekilde Yapıldı.',
                        header: 'Başarılı',
                        icon: 'pi pi-check-circle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                }
                setLoadingUpLoad(false);
            }).catch(()=> setLoadingUpLoad(false))
        }

    }

    return (
        <div>
            <h2 className="page-header">Video</h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Grup No:</label>
                                    <InputText className='w-full' value={groupNo} onChange={e=> setGroupNo(e.target.value)} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Video:</label>
                                    <FileUpload   accept="video/*" multiple={false}  customUpload  chooseLabel={selectedVideo ? "Yüklendi" : "Yükle"} auto onRemove={e=>{
                                        setSelectedVideo(null);
                                    }} uploadHandler={e=>{
                                        setSelectedVideo(e.files);
                                    }} />
                                </div>
                            </div>
                            { 
                                loadVideo > 0 && loadVideo !== 100 ?
                                <div className="fluid formgrid grid">
                                    <div className="field col-12 md:col-12 lg:col-12">
                                        <ProgressBar value={loadVideo}></ProgressBar>
                                    </div>
                                </div> 
                                    : null
                            }
                             
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-3">
                                    <Button className="p-button-info w-full" label="Kaydet" loading={loadingUpLoad} disabled={!groupNo || !selectedVideo} onClick={saveGroupNoVideo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                permissonSMS ?
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card__body">
                                    <div className="fluid formgrid grid">
                                        <div className="field col-12 md:col-12 lg:col-6">
                                            <label className='mr-5 block' htmlFor="grupNo">Grup No:</label>
                                            <InputText className='w-full' inputId="grupNo" value={groupNoShare} onChange={e=> setGroupNoShare(e.target.value)}  />
                                        </div>
                                    </div>
                                    <div className="fluid formgrid grid">
                                        <div className="field col-12 md:col-12 lg:col-3">
                                            <Button className="p-button-info w-full" label="SMS Paylaş" loading={loadingShare} disabled={!groupNoShare} onClick={smsShare} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default Video
