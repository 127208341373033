import AddDonateType from "./pages/AddDonateType";
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import DeleteDonation from "./pages/DeleteDonation";
import Donations from "./pages/Donations";
import DonationTypes from "./pages/DonationTypes";
import MainTabs from "./pages/MainTabs";
import News from "./pages/news/News";
import PaymentControl from "./pages/PaymentControl";
import Users from "./pages/Users";
import Video from "./pages/Video";

export default [
    {
        path: "/",
        component: Dashboard,
        exact: true
    },
    {
        path: "/add-donate-type",
        component: AddDonateType,
        exact: true
    },
    {
        path: "/donation-types",
        component: DonationTypes,
        exact: true
    },
    {
        path: "/donations",
        component: Donations,
        exact: true
    },
    {
        path: "/category",
        component: Category,
        exact: true
    },
    {
        path: "/video",
        component: Video,
        exact: true
    },
    {
        path: "/users",
        component: Users,
        exact: true
    },
    {
        path: "/main-tabs",
        component: MainTabs,
        exact: true
    },
    {
        path: "/remove-data",
        component: DeleteDonation,
        exact: true
    },
    {
        path: "/news",
        component: News,
        exact: true
    },
    {
        path: "/payment-control",
        component: PaymentControl,
        exact: true
    }
    
]