import React, {useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { ContextMenu } from 'primereact/contextmenu';

import './table.css'

const Table = props => {
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'name': { value: '', matchMode: 'contains' },
        }
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedItems, setSelectedItems] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRow, setSelectedRow] = useState();

    const cm = useRef(null);


    const onSort = (event) => {
        setLazyParams(event);
    }

    // const onFilter = (event) => {
    //     event['first'] = 0;
    //     setLazyParams(event);
    // }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedItems(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
                setSelectAll(true);
                setSelectedItems(props.value);
        }
        else {
            setSelectAll(false);
            setSelectedItems([]);
        }
    }
   

    return (
        <>
            <ContextMenu model={props.menuModel} ref={cm} onHide={() => setSelectedRow(null)}/> 
            <DataTable  paginator  totalRecords={totalRecords} 
                contextMenuSelection={selectedRow} onContextMenuSelectionChange={e => setSelectedRow(e.value)} onContextMenu={e => cm.current.show(e.originalEvent)}  
                sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onSort={onSort} resizableColumns columnResizeMode="expand"
                responsiveLayout="scroll"  selection={selectedItems} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange} 
                stripedRows rows={!props.reorderableColumns ? 10 : props?.value?.length} rowsPerPageOptions={!props.reorderableColumns ? [5, 10, 25] : null} {...props}
                emptyMessage="Data yok."  >
                    {
                        props.columns && props.columns.map(item =>  item)
                    }
            </DataTable>
        </>
    )
}

export default Table
