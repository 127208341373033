import React, { useState, useEffect } from 'react'
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import Remotes from '../Remotes';
import { confirmDialog } from 'primereact/confirmdialog';
import Table from '../components/table/Table';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { API_ENDPOINTS } from '../api';


const MainTabs = () => {

    const [tabList, setTabList] = useState([])
    const [displaySlider, setDisplaySlider] = useState(false);
    const [displayUpdateSlider, setDisplayUpdateSlider] = useState(false);

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [link, setLink] = useState();

    const [isShare, setIsShare] = useState(false);
    const [selectedImage, setSelectedImage] = useState(false);
    const [loadingAddSlider, setLoadingAddSlider] = useState(false);

    const [sliders, setSliders] = useState([]);

    const [updateTitle, setUpdateTitle] = useState();
    const [updateDescription, setUpdateDescription] = useState();
    const [updateLink, setUpdateLink] = useState();
    const [updateIsShare, setUpdateIsShare] = useState(false);
    const [selectedId, setSelectedId] = useState();

    const updateSliderDialog = (rowData) => {
        setUpdateTitle(rowData.Title);
        setUpdateDescription(rowData.Description);
        setUpdateIsShare(rowData.IsShare);
        setUpdateLink(rowData.Link);
        setSelectedId(rowData.ID);
        setDisplayUpdateSlider(true);
    }

    const removeSlider = (id) => {
        Remotes.deleteSlider({ ID: id }).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Başarılı Şekilde Silindi',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                getTab();
            }
        })

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => deleteTab(rowData)} />
            </React.Fragment>
        );
    }

    const actionSliderBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-button-info mr-2" onClick={() => updateSliderDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => deleteSlider(rowData)} />
            </React.Fragment>
        );
    }

    const columns = [
        <Column rowReorder style={{ width: '3em' }} />,
        <Column key={2} field="CategoryName" header="Kategori Adı" sortable filter />,
        <Column key={2} field="Title" header="Başlık" sortable filter />,
        <Column key={3} field="Description" header="Açıklama" bodyStyle={{ width: '150px', 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }} sortable filter />,
        <Column body={actionBodyTemplate} exportable={false} ></Column>
    ];

    const booleanChecker = (rowData, item) => {
        if (typeof rowData[item.field] === "boolean") {
            return rowData[item.field] ? "Aktif" : "Pasif";
        } else {
            return rowData[item.field];
        }
    };

    const columnsSlider = [
        <Column rowReorder style={{ width: '3em' }} />,
        <Column key={2} field="Title" header="Başlık" sortable filter />,
        <Column key={2} field="Link" header="Link" sortable filter />,
        <Column key={3} field="Description" header="Açıklama" bodyStyle={{ width: '150px', 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }} sortable filter />,
        <Column key={3} field="IsShare" header="Paylaşım" body={booleanChecker} sortable filter />,
        <Column body={actionSliderBodyTemplate} exportable={false} ></Column>
    ]

    const deleteTab = (rowData) => {
        confirmDialog({
            message: rowData.Title + " silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => removeTab(rowData.ID)
        });

    }

    const deleteSlider = (rowData) => {
        confirmDialog({
            message: rowData.Title + " silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => removeSlider(rowData.ID)
        });
    }

    const removeTab = (Id) => {
        Remotes.deleteTab({ ID: Id }).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Başarılı Şekilde Silindi',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                getTab();
            }
        })
    }

    useEffect(() => {
        getTab();
    }, [])

    const getTab = () => {
        Remotes.getTab().then(r => {
            if (r.status === 200) {
                setTabList(r.data);
            }
        });
        getSlide();
    }

    const getSlide = () => {
        Remotes.getSlider().then(r => {
            if (r.status === 200) {
                setSliders(r.data);
            }
        })
    }

    const onRowReorder = (e) => {
        let copyData = [...e.value];
        let orderArray = [];
        copyData.forEach(element => {
            orderArray.push(element.ID)
        })
        let response = {
            MainTabID: orderArray
        }
        Remotes.orderTab(response).then(r => {
            if (r.status === 200) {
                setTabList(e.value);
            }
        })

    }

    const onRowReorderSlider = (e) => {
        let copyData = [...e.value];
        let orderArray = [];
        copyData.forEach(element => {
            orderArray.push(element.ID)
        })
        let response = {
            ID: orderArray
        }
        Remotes.orderSlider(response).then(r => {
            if (r.status === 200) {
                setSliders(e.value);
            }
        })

    }

    const onHideSlider = () => {
        setDisplaySlider(false);
    }

    const onHideUpdateSlider = () => {
        setDisplayUpdateSlider(false);
    }

    const addSlider = () => {
        if (selectedImage) {
            setLoadingAddSlider(true);

            let response = {
                Title: title,
                Description: description,
                IsShare: isShare,
                Link: link
            }
            Remotes.addSlider(response).then(r => {
                if (r.status === 200) {
                    var bodyFormData = new FormData();
                    bodyFormData.append('homePageId', r.data);
                    bodyFormData.append('image', selectedImage[0]);
                    Remotes.addSliderImage(bodyFormData).then(t => {
                        if (t.status === 200) {
                            confirmDialog({
                                message: 'Slider Başarılı Şekilde Kaydedildi.',
                                header: 'Başarılı',
                                icon: 'pi pi-check-circle',
                                acceptLabel: 'Tamam',
                                rejectClassName: 'hidden',

                            });
                            getSlide();
                            setDisplaySlider(false);
                        }
                        setLoadingAddSlider(false);
                    }).catch(() => setLoadingAddSlider(false));
                }
            }).catch(() => setLoadingAddSlider(false));
        }
        else {
            confirmDialog({
                message: 'Lütfen fotoğraf yükleyiniz',
                header: 'Hata',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',
            });
        }

    }

    const updateSlider = () => {

        let response = {
            ID: selectedId,
            UpdatedModel: {
                Title: updateTitle,
                Description: updateDescription,
                IsShare: updateIsShare,
                Link: updateLink
            }
        }
        Remotes.updateSlider(response).then(r => {
            if (r.status === 200) {
                confirmDialog({
                    message: 'Slider Başarılı Şekilde Düzenlendi.',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',

                });
                onHideUpdateSlider();
                getSlide();
            }
        })
    }

    const cardAddDonationFooter = (
        <div>
            <Button label="Ekle" icon="pi pi-check" loading={loadingAddSlider} onClick={addSlider} />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={() => console.log("temizle")} />
        </div>
    );

    const cardUpdateSliderFooter = (
        <div>
            <Button label="Düzenle" icon="pi pi-check" onClick={updateSlider} />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={() => console.log("temizle")} />
        </div>
    )

    const setOpenDialog = () => {
        setTitle('');
        setDescription('');
        setIsShare(false);
        setSelectedImage(null);
        setDisplaySlider(true);
    }

    return (
        <div>
            <div className='flex justify-content-between' style={{ height: 50 }}>
                <h2 className="page-header mt-auto mb-auto">Slider</h2>
                <Button className="p-button-info" label="Slider Ekle" onClick={setOpenDialog} />

            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table columns={columnsSlider} value={sliders} reorderableColumns onRowReorder={onRowReorderSlider} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-content-between' style={{ height: 50 }}>
                <h2 className="page-header mt-auto mb-auto">Anasite Tab</h2>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table columns={columns} value={tabList} reorderableColumns onRowReorder={onRowReorder} />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Slider Ekle" visible={displaySlider} onHide={onHideSlider} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={cardAddDonationFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Başlık</label>
                        <InputText id="in" className='w-full' value={title} onChange={e => setTitle(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Açıklama</label>
                        <InputText id="in" className='w-full' value={description} onChange={e => setDescription(e.target.value)} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Link</label>
                        <InputText id="in" className='w-full' value={link} onChange={e => setLink(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Paylaşılsın Mı?</label>
                        <InputSwitch id="in" className='d-block' checked={isShare} onChange={r => setIsShare(r.value)} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Fotoğraf:</label>
                        <FileUpload accept="image/*" customUpload chooseLabel={selectedImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                            setSelectedImage(null);
                        }} uploadHandler={e => {
                            setSelectedImage(e.files);
                        }} />
                    </div>
                </div>

            </Dialog>
            <Dialog header="Slider Düzenle" visible={displayUpdateSlider} onHide={onHideUpdateSlider} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={cardUpdateSliderFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Başlık</label>
                        <InputText id="in" className='w-full' value={updateTitle} onChange={e => setUpdateTitle(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Açıklama</label>
                        <InputText id="in" className='w-full' value={updateDescription} onChange={e => setUpdateDescription(e.target.value)} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Link</label>
                        <InputText id="in" className='w-full' value={updateLink} onChange={e => setUpdateLink(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Paylaşılsın Mı?</label>
                        <InputSwitch id="in" className='d-block' checked={updateIsShare} onChange={r => setUpdateIsShare(r.value)} />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 ">
                        <img src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_SLIDER_IMAGE + selectedId} style={{width: '100%'}} />
                    </div>
                </div>

            </Dialog>
        </div>
    )
}

export default MainTabs