import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';
import Remotes from '../../Remotes';
import { confirmDialog } from 'primereact/confirmdialog';
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import { Column } from 'primereact/column';

import './news.css';
import Table from '../../components/table/Table';
import { API_ENDPOINTS } from '../../api';

const News = () => {

    const[displayDialog, setDisplayDialog] = useState(false);
    const[newsTitle, setNewsTitle] = useState();
    const[newsDescription, setNewsDescription] = useState();
    const[newsSourceCode, setNewsSourceCode] = useState();
    const[newsIsShare, setNewsIsShare] = useState(false);
    const[selectedImage, setSelectedImage] = useState();
    const[loadingSave, setLoadingSave] = useState(false);
    const[displayDialogUpdate, setDisplayDialogUpdate] = useState(false);
    const[newsTitleUpdate, setNewsTitleUpdate] = useState();
    const[newsDescriptionUpdate, setNewsDescriptionUpdate] = useState();
    const[newsSourceCodeUpdate, setNewsSourceCodeUpdate] = useState();
    const[newsIsShareUpdate, setNewsIsShareUpdate] = useState(false);
    const[selectedImageUpdate, setSelectedImageUpdate] = useState();
    const[loadingSaveUpdate, setLoadingSaveUpdate] = useState(false);
    const[newsSelectedID, setNewsSelectedID] = useState(false);
    const[news, setNews] = useState([]);
    const [view, setView] = useState('Tablo');
    const optionsView = ['Tablo', 'Liste'];

    const onHide = () => {
        setDisplayDialog(false);
    }

    const onHideUpdate= () => {
        setDisplayDialogUpdate(false);
    }

    const getNews = () => {
        Remotes.getNews().then(r=>{
            if(r.status === 200) {
                setNews(r.data);
            }
        })

    }

    useEffect(()=> {
        getNews();

    }, [])

    const saveNews = () => {
        setLoadingSave(true);
        let response = {
            Title: newsTitle,
            Description: newsDescription,
            SourceCode: newsSourceCode,
            IsShare: newsIsShare
        }
        Remotes.saveNews(response).then(r=>{
            if(r.status === 200) {
                var formData = new FormData();
                formData.append('newsId', r.data);
                formData.append('image', selectedImage[0]); 

                Remotes.saveNewsImage(formData).then(t=>{
                    if(t.status === 200) {
                        confirmDialog({
                            message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                            header: 'Başarılı',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',
                        });
                        setLoadingSave(false);
                        setDisplayDialog(false);
                        getNews();
                    }
                }).catch(()=> setLoadingSave(false))
                
            }

        }).catch(()=> setLoadingSave(false))
    }

    const updateNews = () => {

        setLoadingSaveUpdate(true);
        let response = {
            ID: newsSelectedID,
            Title: newsTitleUpdate,
            Description: newsDescriptionUpdate,
            SourceCode: newsSourceCodeUpdate,
            IsShare: newsIsShareUpdate
        }
        Remotes.updateNews(response).then(r=>{
            if(r.status === 200) {
                if(selectedImageUpdate) {
                    var formData = new FormData();
                    formData.append('newsId', newsSelectedID);
                    formData.append('image', selectedImageUpdate[0]); 
                    Remotes.updateNewsImage(formData).then(t=>{
                        if(t.status === 200) {
                            confirmDialog({
                                message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                header: 'Başarılı',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Tamam',
                                rejectClassName: 'hidden',
                            });
                            setLoadingSaveUpdate(false);
                            getNews();
                        }
                    }).catch(()=> setLoadingSaveUpdate(false))

                } else {
                    confirmDialog({
                        message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'hidden',
                    });
                    setLoadingSaveUpdate(false);

                }
                
            }

        }).catch(()=> setLoadingSaveUpdate(false))

    }

    const removeNews = () =>{
        let response= {
            ID: newsSelectedID
        };
        Remotes.deleteNews(response).then(r=>{
            if(r.status === 200) {
                confirmDialog({
                    message: 'Silme İşlemi Başarılı Bir Şekilde Yapıldı.',
                    header: 'Başarılı',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Tamam',
                    rejectClassName: 'hidden',
                });
                setDisplayDialogUpdate(false);
                getNews();
            }
        })
    }

    const deleteNews= () => {
        confirmDialog({
            message: "Haber silinsin mi?",
            header: 'Sil',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: removeNews
        });
    }

    const cardAddDonationFooter = (
        <div>
            <Button label="Ekle" icon="pi pi-check" loading={loadingSave} onClick={saveNews} />
            <Button label="Temizle" icon="pi pi-times" className="p-button-danger" onClick={()=> console.log("temizle")}  />
        </div>
    );

    const cardUpdateFooter = (
        <div>
            <Button label="Düzenle" icon="pi pi-check" loading={loadingSaveUpdate} onClick={updateNews} />
            <Button label="Sil" icon="pi pi-times" className="p-button-danger" onClick={deleteNews}  />
        </div>
    )

    const newsClick = (selectedNews) =>{
        setNewsSelectedID(selectedNews.ID);
        setNewsTitleUpdate(selectedNews.Title);
        setNewsDescriptionUpdate(selectedNews.Description);
        setNewsIsShareUpdate(selectedNews.IsShare);
        setNewsSourceCodeUpdate(selectedNews.SourceCode);
        setDisplayDialogUpdate(true);
    }

    const onRowReorder = (e) => {
        let copyData = [...e.value];
        let orderArray = [];
        copyData.forEach(element => {
            orderArray.push(element.ID)
        })
        let response = {
            NewsID: orderArray
        }
        Remotes.orderNews(response).then(r => {
            if (r.status === 200) {
                setNews(e.value);
            }
        })

    }

    const dateTemplate =(rowData)=>{
        let date = new Date(rowData.CreatedDate)
        return date.toLocaleString();
   }

    const columns = [
        <Column rowReorder style={{ width: '3em' }} />,
        <Column field="Title" header="Haber Başlığı" sortable filter />,
        <Column field="Description" header="Açıklama" style={{ maxWidth: '8rem' }} sortable filter />,
        <Column field="CreatedDate" header="Kayıt Tarihi" body={dateTemplate} sortable filter />
    ]

    return (
        <div>
            <div className='flex justify-content-between' style={{height: 50}}>
                <h2 className="page-header mt-auto mb-auto">Bağışlar</h2>
                <Button className="p-button-info addDonationButton" label="Haber Ekle" onClick={()=>setDisplayDialog(true)} /> 
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                        <div className="fluid formgrid grid">
                        <div className='flex justify-content-end w-full my-2'>
                                <SelectButton value={view} options={optionsView} onChange={(e) => setView(e.value)} />
                            </div>
                            {
                                view === 'Tablo' ?
                                news?.map((item) => {
                                    return (
                                    <div className="col-12 md:col-12 lg:col-4 col__news" onClick={()=>newsClick(item)}>
                                        <div className='card p-3'>
                                            <div className='card__header'>
                                                <img className='news__image' src={API_ENDPOINTS.BASE_URL + API_ENDPOINTS.GET_NEWS_IMAGE + item.ID} alt='haberler' />
                                            </div>
                                            <div className='card__body mt-2'>
                                                <p className='news__title'>{item.Title}</p>
                                                <p className='news__description'>{item.Description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })
                                : <Table className="w-full" columns={columns} reorderableColumns onRowReorder={onRowReorder} value={news} />
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Dialog header="Haber Ekle" visible={displayDialog} onHide={onHide} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} footer={cardAddDonationFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Haber Başlığı</label>
                        <InputText id="in" className='w-full' value={newsTitle} onChange={e=>setNewsTitle(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Açıklama</label>
                        <InputTextarea className='w-full' value={newsDescription} onChange={e=>setNewsDescription(e.target.value)}  />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Fotoğraf</label>
                        <FileUpload   accept="image/*"  customUpload  chooseLabel={selectedImage ? "Yüklendi" : "Yükle"} auto onRemove={e=>{
                            setSelectedImage(null);
                        }} uploadHandler={e=>{
                            setSelectedImage(e.files);
                        }} />
                    </div>
                
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Paylaşılsın Mı?</label>
                        <InputSwitch  checked={newsIsShare}  onChange={(e) => setNewsIsShare(e.value)}  ></InputSwitch>
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-12">
                        <label className='mr-5 block'>İçerik</label>
                        <Editor className='w-full' value={newsSourceCode} onTextChange={e=>setNewsSourceCode(e.htmlValue)}  />
                    </div>
                </div>
            </Dialog>
            <Dialog header="Haber Düzenle" visible={displayDialogUpdate} onHide={onHideUpdate} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} footer={cardUpdateFooter} >
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Haber Başlığı</label>
                        <InputText id="in" className='w-full' value={newsTitleUpdate} onChange={e=>setNewsTitleUpdate(e.target.value)} />
                    </div>
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Açıklama</label>
                        <InputTextarea className='w-full' value={newsDescriptionUpdate} onChange={e=>setNewsDescriptionUpdate(e.target.value)}  />
                    </div>
                </div>
                <div className="fluid formgrid grid">
                <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Fotoğraf</label>
                        <FileUpload   accept="image/*"  customUpload  chooseLabel={selectedImageUpdate ? "Yüklendi" : "Yükle"} auto onRemove={e=>{
                            setSelectedImageUpdate(null);
                        }} uploadHandler={e=>{
                            setSelectedImageUpdate(e.files);
                        }} />
                    </div>
                
                    <div className="field col-12 md:col-12 lg:col-6">
                        <label className='mr-5 block'>Paylaşılsın Mı?</label>
                        <InputSwitch  checked={newsIsShareUpdate}  onChange={(e) => setNewsIsShareUpdate(e.value)}  ></InputSwitch>
                    </div>
                </div>
                <div className="fluid formgrid grid">
                    <div className="field col-12 md:col-12 lg:col-12">
                        <label className='mr-5 block'>İçerik</label>
                        <Editor className='w-full' value={newsSourceCodeUpdate} onTextChange={e=>setNewsSourceCodeUpdate(e.htmlValue)}  />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default News
