import React, { useEffect, useState } from 'react'
import './topnav.css'
import { Link } from 'react-router-dom'
import Dropdown from '../dropdown/Dropdown'
import ThemeMenu from '../thememenu/ThemeMenu'
import notifications from '../../assets/JsonData/notification.json'
import user_image from '../../assets/images/tuat.png'
import user_menu from '../../assets/JsonData/user_menus.json'
import { useDispatch, useSelector } from 'react-redux'
import Action from '../../redux/actions/Action'



const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
)

const renderUserToggle = (user) => (
    <div className="topnav__right-user">
        {/* <div className="topnav__right-user__image">
            <img src={user.image} alt="" />
        </div> */}
        <div className="topnav__right-user__name">
            {user.display_name}
        </div>
    </div>
)

const renderUserMenu =(item, index) => (
    <Link to={item.path} key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span>{item.content}</span>
        </div>
    </Link>
)


const Topnav = () => {
    const [toggleClick, setToggleClick] = useState(false);
    const dispacth = useDispatch();
    const [user, setUser]= useState()

    //setUser(useSelector(state => state.Reducer.user))

    useEffect(()=>{
        if(JSON.parse(localStorage.getItem("User")) != null && !user) {
            setUser(JSON.parse(localStorage.getItem("User")))
          }

    }, [])
    

    const curr_user = {
        display_name: user ? user.Username : '',
        image: user_image
    }

    return (
        <div className='topnav'>
            <div className='header__toggle' onClick={()=> {setToggleClick(!toggleClick); dispacth(Action.openSideBar(true))}} >
                        <i className={'bx bx-menu'} id="header-toggle"></i>
                    </div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    />
                </div>
                {/* <div className="topnav__right-item">
                    <Dropdown
                        icon='bx bx-bell'
                        badge='12'
                        contentData={notifications}
                        renderItems={(item, index) => renderNotificationItem(item, index)}
                        renderFooter={() => <Link to='/'>View All</Link>}
                    /> */}
                    {/* dropdown here */}
                {/* </div> */}
                {/* <div className="topnav__right-item">
                    <ThemeMenu/>
                </div> */}
            </div>
        </div>
    )
}

export default Topnav
