import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Remotes from '../Remotes';
import EmailEditor from 'react-email-editor';

const AddDonateType = () => {
    const emailEditorRef = useRef(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [smsDescription, setSmsDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [isShare, setIsShare] = useState(false);
    const [isShareMain, setIsShareMain] = useState(false);
    const [isVekalet, setIsVekalet] = useState(false);
    const [subGroup, setSubGroup] = useState(1);
    const [donationKind, setDonationKind] = useState(2);
    const [extraProperty, setExtraProperty] = useState(false);
    const [extraDescription, setExtraDescription] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [category, setCategory] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [selectedImageForMain, setSelectedImageForMain] = useState();

    const [selectedProjectImage, setSelectedProjectImage] = useState();
    const [isHisse, setIsHisse] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [keyWords, setKeyWords] = useState();


    const [saveLoading, setSaveLoading] = useState(false);
    const subGroupOptions = [{ label: 'Vacip', value: 1 }, { label: 'Diğer', value: 2 }, { label: 'Su Kuyusu', value: 3 }, { label: 'Asya Su Kuyusu', value: 4 }];

    useEffect(() => {
        Remotes.getCategory().then(r => {
            if (r.status === 200) {
                let options = [];
                r.data && r.data.map(t => {
                    options.push({ label: t.Name, value: t.ID })
                })
                setCategoryOptions(options);
            }
        });

    }, []);

    const [properties, setProperties] = useState([
        {
            propertyName: "",
            propertyType: 0,
            selectOptions: ""
        }
    ]);

    const [generalDonationType, setGeneralDonationType] = useState([
        {
            buttonName: "",
            amount: 0
        }
    ]);

    const clearAll = () => {
        setTitle('');
        setDescription('');
        setSmsDescription('');
        setAmount('');
        setIsShare(false);
        setIsHisse(false);
        setIsVekalet(false);
        setIsProject(false);
        setDonationKind(2);
        setProperties([{
            propertyName: "",
            propertyType: 0,
            selectOptions: ""
        }]);
        setGeneralDonationType([
            {
                buttonName: "",
                amount: 0
            }
        ]);

    }

    const addProperty = () => {
        setProperties([...properties, {
            propertyName: "",
            propertyType: 0,
            selectOptions: ""
        }])

    }

    const removeProperty = (index) => {
        if (properties.length > 1) {
            let allProperties = properties.filter((item, i) => i !== index);
            setProperties(allProperties);
        }
    }


    const addButton = () => {
        setGeneralDonationType([...generalDonationType, {
            buttonName: "",
            amount: 0
        }])
    }

    const removeGeneralDonationType = (index) => {
        if (generalDonationType.length > 1) {
            let allGeneralDonationType = generalDonationType.filter((item, i) => i !== index);
            setGeneralDonationType(allGeneralDonationType);
        }
    }

    const saveAll = () => {
        if (selectedImage) {
            setSaveLoading(true);
            let addDonationTypeProperties = [];
            properties && properties.map(item => {
                let obj = {
                    PropertyName: item.propertyName,
                    IsSelect: item.propertyType,
                    Value: item.propertyType ? item.selectOptions.split(',') : null
                }
                addDonationTypeProperties.push(obj);
            });
            let genericDonationTypeRequest = [];
            generalDonationType && generalDonationType.map(item => {
                let obj = {
                    ButtonName: item.buttonName,
                    Amount: item.amount
                }
                genericDonationTypeRequest.push(obj);
            });
            let response = {
                Title: title,
                Description: description,
                SMSDescription: smsDescription,
                Amount: amount ? amount : 0,
                IsShare: isShare,
                IsShareMain: isShareMain,
                IsHisse: isHisse,
                IsProject: isProject,
                IsVekalet: isVekalet,
                categoryID: category,
                KeyWords: keyWords,
                SubGroup: isHisse ? subGroup : null,
                SourceCode: null,
                SourceCodeJSON: null,
                AddDonationTypePropertiesViewModel: extraProperty ? addDonationTypeProperties : [],
                DonationKindID: donationKind,
                GenericDonationTypeRequestViewModels: donationKind === 1 ? genericDonationTypeRequest : []
            }
            const addDonationType = () =>{
                Remotes.postAddDonationType(response).then(r => {
                    if (r.status === 200) {
                        var bodyFormData = new FormData();
                        bodyFormData.append('donationTypeId', r.data);
                        bodyFormData.append('image', selectedImage[0]);
                        Remotes.saveDonationImage(bodyFormData).then(t => {
                            if (t.status === 200) {
                                var projectFormData = new FormData();
                                projectFormData.append('projectId', r.data);
                                projectFormData.append('image', selectedProjectImage[0]);
                                Remotes.saveDonationProjectImage(projectFormData).then(t => {
                                    if (t.status === 200) {
                                        var projectFormDatadonation = new FormData();
                                        projectFormDatadonation.append('id', r.data);
                                        projectFormDatadonation.append('image', selectedImageForMain[0]);
                                        Remotes.saveDonationTabImage(projectFormDatadonation).then(tab => {
                                            if(tab.status === 200) {
                                                confirmDialog({
                                                    message: 'Kayıt Başarılı Şekilde Kaydedildi.',
                                                    header: 'Başarılı',
                                                    icon: 'pi pi-check-circle',
                                                    acceptLabel: 'Tamam',
                                                    rejectClassName: 'hidden',
                                                });
                                                setSaveLoading(false);

                                            } else {
                                                let response = {
                                                    ID: r.data
                                                }
                                                Remotes.removeDonation(response).then(y => {
                                                    confirmDialog({
                                                        message: 'Bir Hata Oluştu.',
                                                        header: 'Hata',
                                                        icon: 'pi pi-info-circle',
                                                        acceptClassName: 'p-button-danger',
                                                        acceptLabel: 'Tamam',
                                                        rejectClassName: 'hidden',
                                                    });
                                                    setSaveLoading(false);

                                                })
                                            }
                                        })
                                    }
                                    else {
                                        let response = {
                                            ID: r.data
                                        }
                                        Remotes.removeDonation(response).then(y => {
                                            confirmDialog({
                                                message: 'Bir Hata Oluştu.',
                                                header: 'Hata',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                acceptLabel: 'Tamam',
                                                rejectClassName: 'hidden',
                                            });
                                        })
                                    }
                                    setSaveLoading(false);
                                });
                            } else {
                                let response = {
                                    ID: r.data
                                }
                                Remotes.removeDonation(response).then(y => {
                                    confirmDialog({
                                        message: 'Bir Hata Oluştu.',
                                        header: 'Hata',
                                        icon: 'pi pi-info-circle',
                                        acceptClassName: 'p-button-danger',
                                        acceptLabel: 'Tamam',
                                        rejectClassName: 'hidden',

                                    });
                                })
                            }
                        })

                    } else {
                        confirmDialog({
                            message: 'Bir Hata Oluştu.',
                            header: 'Hata',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button-danger',
                            acceptLabel: 'Tamam',
                            rejectClassName: 'hidden',

                        });
                        setSaveLoading(false);
                    }
                }).catch(e => {
                    setSaveLoading(false);
                })

            }
            if(emailEditorRef?.current) {
                emailEditorRef.current.editor.exportHtml((data) => {
                    const { design, html } = data;
                    response.SourceCode = html;
                    response.SourceCodeJSON = JSON.stringify(design);
                    addDonationType();
                });
            } else {
                addDonationType();
            }
        } else {
            confirmDialog({
                message: 'Resim Yükleyiniz',
                header: 'Hata',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',

            });
            setSaveLoading(false);
        }
    }


    /*
        DonationKind:
        1: Genel Bağış Tipi
        2: Normal Bağış

        SubGroup:
        1: Vacip
        2: Diğer

    */

    return (
        <div>
            <h2 className="page-header">
                Bağış Ekle
            </h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Bağış Başlığı:</label>
                                    <InputText id="in" className='w-full' value={title} onChange={r => {
                                        setTitle(r.target.value);
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Kategori</label>
                                    <Dropdown className='w-full' options={categoryOptions}
                                        placeholder="Seçiniz..." value={category} onChange={e => {
                                            setCategory(e.value);
                                        }} />
                                </div>
                            </div>

                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Bağış Açıklaması:</label>
                                    <InputTextarea id="in" className='w-full' rows={5} autoResize value={description} onChange={r => {
                                        setDescription(r.target.value);
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>SMS Açıklaması:</label>
                                    <InputTextarea id="in" className='w-full' rows={5} tooltip={["Sayın UMUT SARIKAYA İz Bıraktınız!\n" + smsDescription + "\nİletişim: 0850 532 25 40\nİnsan İzi Derneği"]} tooltipOptions={{ position: 'top' }} autoResize value={smsDescription} onChange={r => {
                                        setSmsDescription(r.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Bağış Tutarı:</label>
                                    <InputNumber id="in" disabled={donationKind === 1} className='w-full' mode="currency" currency="TRY" locale="tr-TR" value={amount} onChange={r => {
                                        const re = /^[0-9\b]+$/;
                                        if (re.test(r.value)) {
                                            setAmount(r.value);
                                        }
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Yayınlansın Mı? :</label>
                                    <InputSwitch id="in" className=' d-block' checked={isShare} onChange={r => {
                                        setIsShare(r.value);
                                    }} />
                                </div>

                            </div>
                            <div className="fluid formgrid grid">

                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Hisseli Mi? :</label>
                                    <InputSwitch id="in" className=' d-block' checked={isHisse} onChange={r => {
                                        setIsHisse(r.value);
                                    }} />
                                </div>
                                {
                                    isHisse ?
                                        <div className="field col-12 md:col-12 lg:col-6">
                                            <label className='mr-5 block'>Gruplama</label>
                                            <Dropdown className='w-full' options={subGroupOptions}
                                                placeholder="Seçiniz..." value={subGroup} onChange={e => {
                                                    setSubGroup(e.value);
                                                }} />
                                        </div> : null
                                }

                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Vekalet İstensin Mi?:</label>
                                    <InputSwitch id="in" className=' d-block' checked={isVekalet} onChange={r => {
                                        setIsVekalet(r.value);
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Ana Siteye Eklensin Mi? :</label>
                                    <InputSwitch id="in" className=' d-block' checked={isShareMain} onChange={r => {
                                        setIsShareMain(r.value);
                                    }} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Projelerde Gözüksün Mü?:</label>
                                    <InputSwitch id="in" className=' d-block' checked={isProject} onChange={r => {
                                        setIsProject(r.value);
                                    }} />
                                </div>
                            </div>
                            <div className="fluid formgrid grid">
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Fotoğraf:</label>
                                    <FileUpload accept="image/*" customUpload chooseLabel={selectedImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                        setSelectedImage(null);
                                    }} uploadHandler={e => {
                                        setSelectedImage(e.files);
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Projeler için Tasarım:</label>
                                    <FileUpload accept="image/*" customUpload chooseLabel={selectedProjectImage ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                        setSelectedProjectImage(null);
                                    }} uploadHandler={e => {
                                        setSelectedProjectImage(e.files);
                                    }} />
                                </div>
                                <div className="field col-12 md:col-12 lg:col-6">
                                    <label className='mr-5 block'>Anasayfa için Tasarım:</label>
                                    <FileUpload accept="image/*" customUpload chooseLabel={selectedImageForMain ? "Yüklendi" : "Yükle"} auto onRemove={e => {
                                        setSelectedImageForMain(null);
                                    }} uploadHandler={e => {
                                        setSelectedImageForMain(e.files);
                                    }} />
                                </div>
                            </div>
                            <div className="fluid my-5">
                                <h3>Bağış Tipi</h3>
                            </div>
                            <div className="field-radiobutton" >
                                <RadioButton inputId="normal" name="normal" value="Normal Bağış"
                                    checked={donationKind === 2} onChange={e => {
                                        setDonationKind(2);
                                    }} />
                                <label htmlFor="normal">Normal Bağış</label>
                            </div>
                            <div className="field-radiobutton" >
                                <RadioButton inputId="genel" name="genel" value="Genel Bağış Tipi"
                                    checked={donationKind === 1} onChange={e => {
                                        setDonationKind(1);
                                    }} />
                                <label htmlFor="genel">Genel Bağış Tipi</label>
                            </div>
                            {
                                donationKind === 1 ?
                                    <>
                                        <div className="fluid my-5">
                                            <h3>Genel Bağış Özelleştir</h3>
                                        </div>
                                        {generalDonationType && generalDonationType.map((item, index) => {
                                            return (
                                                <div className="fluid formgrid grid" key={index}>
                                                    <div className="field col-12 md:col-12 lg:col-4">
                                                        <label className='mr-5 block'>Buton Adı</label>
                                                        <InputText id="in" className='w-full' value={item.buttonName} onChange={e => {
                                                            let newGenenal = [...generalDonationType];
                                                            newGenenal[index].buttonName = e.target.value;
                                                            setGeneralDonationType(newGenenal);
                                                        }} />
                                                    </div>
                                                    <div className="field col-12 md:col-12 lg:col-4">
                                                        <label className='mr-5 block'>Tutar</label>
                                                        <InputNumber id="in" className='w-full' mode="currency" currency="TRY" locale="tr-TR" value={item.amount} onChange={e => {
                                                            const re = /^[0-9\b]+$/;
                                                            if (re.test(e.value)) {
                                                                let newGenenal = [...generalDonationType];
                                                                newGenenal[index].amount = e.value;
                                                                setGeneralDonationType(newGenenal);
                                                            }

                                                        }} />
                                                    </div>
                                                    <div className="field  ml-3 flex align-items-end justify-content-center">
                                                        <Button className="p-button-danger" label="Sil" onClick={() => removeGeneralDonationType(index)} />
                                                    </div>
                                                </div>
                                            )

                                        })}
                                        <div className='fluid formgrid grid'>
                                            <div className="field col">
                                                <Button className="p-button-success" label="Buton Ekle" onClick={addButton} />
                                            </div>
                                        </div>

                                    </> : null
                            }
                            <div className="fluid my-5">
                                <h3>Anahtar Kelimeler</h3>
                            </div>
                            <div>
                                <InputTextarea className='w-full' value={keyWords} onChange={(e)=> setKeyWords(e.target.value)} />
                            </div>
                            <div className="fluid my-5">
                                <h3>Extra Açıklama</h3>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="extraDesc" checked={extraDescription} onChange={e => setExtraDescription(e.checked)} />
                                <label htmlFor="extraDesc">Extra özellik eklemek istiyorum.</label>
                            </div>
                            <div className={!extraDescription ? 'hidden' : null} >
                                <EmailEditor ref={emailEditorRef} minHeight="700px" />
                            </div>
                            <div className="fluid my-5">
                                <h3>Extra Özellikler</h3>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="binary" checked={extraProperty} onChange={e => setExtraProperty(e.checked)} />
                                <label htmlFor="binary">Extra özellik eklemek istiyorum.</label>
                            </div>

                            {extraProperty ?
                                properties && properties.map((pro, index) => {
                                    return (<>
                                        <div className="fluid formgrid grid" key={index}>
                                            <div className="field col-12 md:col-12 lg:col-3">
                                                <label className='mr-5 block'>Özellik Adı</label>
                                                <InputText id="in" className='w-full' value={pro.propertyName} onChange={e => {
                                                    let newPro = [...properties];
                                                    newPro[index].propertyName = e.target.value;
                                                    setProperties(newPro);
                                                }} />
                                            </div>
                                            <div className="field col-12 md:col-12 lg:col-3">
                                                <label className='mr-5 block'>Özellik Türü</label>
                                                <Dropdown className='w-full' options={[{ label: 'Paragraf', value: 0 }, { label: 'Seçmeli', value: 1 }]}
                                                    placeholder="Seçiniz..." value={pro.propertyType} onChange={e => {
                                                        let newPro = [...properties];
                                                        newPro[index].propertyType = e.value;
                                                        setProperties(newPro);
                                                    }} />
                                            </div>
                                            {
                                                pro.propertyType === 1 ?
                                                    <div className="field col-12 md:col-12 lg:col-3">
                                                        <label className='mr-5 block'>Seçenekler</label>
                                                        <InputText id="in" className='w-full' value={pro.selectOptions} onChange={e => {
                                                            let newPro = [...properties];
                                                            newPro[index].selectOptions = e.target.value;
                                                            setProperties(newPro);

                                                        }} />
                                                    </div> : null
                                            }

                                            <div className="field ml-3 flex align-items-end justify-content-center">
                                                <Button className="p-button-danger" label="Sil" onClick={() => removeProperty(index)} />
                                            </div>
                                        </div>
                                        {
                                            index === properties.length - 1 ?
                                                <div className='fluid formgrid grid'>
                                                    <div className="field col">
                                                        <Button className="p-button-success" label="Özellik Ekle" onClick={addProperty} />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </>)
                                })
                                : null}
                            <div className="fluid formgrid grid mt-5">
                                <div className="field col-6 md:col-6 lg:col-3">
                                    <Button className="p-button-info w-full" label="Kaydet" iconPos="right" loading={saveLoading} onClick={saveAll} />
                                </div>
                                <div className="field col-6 md:col-6 lg:col-3">
                                    <Button className="p-button-secondary w-full" label="Temizle" onClick={clearAll} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDonateType;
