import React, {useEffect} from 'react'
import Sidebar from '../sidebar/Sidebar'
import TopNav from '../topnav/TopNav'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from '../../pages/Dashboard'
import Login from '../../pages/Login'
import AddDonateType from '../../pages/AddDonateType'
import Donations from '../../pages/Donations'
import PublicRoute from "../layout/PublicRoute";
import PrivateRoute from "../layout/PrivateRoute";
import routes from '../../routes';
import './layout.css';
import Video from '../../pages/Video'
import Action from '../../redux/actions/Action';

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
 



const Layout = () => {
    const dispacth = useDispatch();


    const outsideClick = () => {
        dispacth(Action.openSideBar(false))
    }
    useEffect(()=>{
        addLocale('tr', {
            closeText: 'Kapat',
            prevText: 'Geri',
            nextText: 'İleri',
            currentText: 'Bugün',
            monthNames: ['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'],
            monthNamesShort: ['Oca','Şub','Mar','Nis','May','Haz', 'Tem','Ağu','Eyl','Eki','Kas','Ara'],
            dayNames: ['Pazar','Pazartesi','Salı','Çarşamba','Perşembe','Cuma','Cumartesi'],
            dayNamesShort: ['Pz','Pt','Sa','Ça','Pe','Cu','Ct'],
            dayNamesMin: ['Pz','Pt','Sa','Ça','Pe','Cu','Ct'],
            weekHeader: 'Hf',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: '',
            timeOnlyTitle: 'Zaman Seçiniz',
            timeText: 'Zaman',
            hourText: 'Saat',
            minuteText: 'Dakika',
            secondText: 'Saniye',
            ampm: false,
            month: 'Ay',
            week: 'Hafta',
            day: 'Gün',
            allDayText : 'Tüm Gün',
            startsWith: 'Başlayan',
            contains: 'İçeren',
            notContains: 'İçermeyen',
            endsWith: 'Biten',
            equals: 'Eşit',
            notEquals: 'Eşit Değil',
            noFilter: 'Filtre Yok',
            matchAll: 'Hepsini Eşleştir',
            matchAny: 'Hiçbirini Eşleştirme',
            addRule: 'Yeni Kural Ekle',
            remoceRule: 'Kuralı Sil',
            choose: 'Seçim',
            upload: 'Yükle',
            clear: 'Temizle',
            apply: 'Uygula'
    
        });
        locale('tr');
    }, [])

    

    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/login" component={(props) => {
                    return (
                        <Login/>
                    );
                }}/>
                <Route path="/logout" render={(props) => {

                    sessionStorage.clear();
                    return(
                        <Redirect to='/login' />
                    )
                    
                }}/>
                { routes && routes.map((route, index) => {
                    return <PrivateRoute key={index} path={route.path} exact={route.exact} component={(props) => {
                        return (
                            <div className={`layout`} >
                                <Sidebar {...props}/>
                                <div className="layout__content" >
                                    <TopNav/>
                                    <div className="layout__content-main" onClick={outsideClick}>
                                       <route.component />
                                    </div>
                                </div>
                            </div>
                            );
                        }}
                        />

                    })
                }

            </Switch>
        </BrowserRouter>
    )
}

export default Layout;
